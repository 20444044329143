import React, { useState, useRef, useEffect } from 'react';
import { PostHogSendMonitoring } from '../../../utils/PostHogSendEvents';
import { ArrowDown2 } from 'iconsax-react';
import styles from '../../collections/components/Collections/FilterOrdered.module.scss';

const dropdownData = [
  { id: 4, value: '-updated_date', label: 'Última Atualização' },
  { id: 1, value: 'query', label: 'Ordem Alfabética (a-z)' },
  { id: 2, value: '-query', label: 'Ordem Alfabética (z-a)' },
  {
    id: 3,
    value: '-registration_date',
    label: 'Data de Criação (mais recente)',
  },
  { id: 5, value: 'registration_date', label: 'Data de Criação (mais antigo)' },
];

const OrderBy = ({ changeFilters, whereSearch, monitoringCount }) => {
  const [order, setOrder] = useState('-updated_date');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filterText, setFilterText] = useState('');
  const dropdownRef = useRef(null);

  const filteredOptions = dropdownData.filter((option) =>
    option.label.toLowerCase().includes(filterText.toLowerCase()),
  );

  const handleOptionClick = (option) => {
    setOrder(option.value);
    changeFilters({ where: whereSearch, ordering: option.value });
    PostHogSendMonitoring('order_by_monitoring');
    setDropdownOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={styles.container}
      ref={dropdownRef}
      style={{ margin: '30px 0px 20px 0px' }}
    >
      <button
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className={styles.button}
      >
        Ordenar por:
        <span className={styles.selectedOption}>
          {dropdownData.find((item) => item.value === order)?.label}
          <ArrowDown2 size="12" color="#000" />
        </span>
      </button>

      {dropdownOpen && (
        <div className={styles.dropdown}>
          <div className={styles.inputContainer}>
            <input
              type="text"
              placeholder="Pesquisar"
              className={styles.filterInput}
              value={filterText}
              onChange={handleFilterChange}
            />
          </div>
          <ul className={styles.optionList}>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <li
                  key={option.id}
                  className={`${styles.option} ${order === option.value ? styles.active : ''}`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.label}
                </li>
              ))
            ) : (
              <li className={styles.noOptions}>Nenhum resultado encontrado</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default OrderBy;
