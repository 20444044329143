import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogOverlay,
  DialogPortal,
} from '../../../../../../components/ui/dialog';
import { useLocation } from 'react-router-dom';
import capa_notification_new from '../../../../../../assets/svgs/capa-notification-new.svg';
import './notification-resume-ia.css';
import { Button } from 'rsuite';
import { ArrowRight2, ArrowLeft2 } from 'iconsax-react';
import { ResumeInteiroTeorNotification } from '../../../../../../components/Skeleton';

const NOTIFICATION_KEY = 'modal-grande-resumo-inteiro-teor-v2';

export const NotificationResumeIA = () => {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const totalPages = 2;

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem(NOTIFICATION_KEY, 'false');
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  useEffect(() => {
    const _show = JSON.parse(localStorage.getItem(NOTIFICATION_KEY));

    if (_show === null) {
      setOpen(true);
    } else {
      setOpen(_show);
    }
  }, [pathname]);

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogPortal>
        <DialogOverlay className="bg-black/30" />
        <DialogContent className="notification-resume-container">
          <div
            className={`notification-resume-context ${page === 2 ? ' margin-step-2' : ''}`}
          >
            {page === 1 ? (
              <img src={capa_notification_new} width={352} height={200} />
            ) : (
              <ResumeInteiroTeorNotification />
            )}

            <span className="title-notification">
              Resuma com IA as decisões selecionadas
            </span>
            <span className="describe-notification">
              {page === 1
                ? 'Nossa IA resume e trás em tópicos as principais informações sobre as decisões selecionadas.'
                : 'Agora você pode selecionar as decisões que deseja e gerar um resumo automático dos principais pontos. Mais praticidade e agilidade para sua pesquisa jurídica!'}
            </span>

            <div className="page-indicator">
              {[...Array(totalPages)].map((_, index) => (
                <div
                  key={index}
                  className={`dot ${page === index + 1 ? 'active' : ''}`}
                  onClick={() => handlePageClick(index + 1)}
                />
              ))}
            </div>

            <div className="page-navigation">
              {page == 1 ? (
                <Button
                  appearance="primary"
                  onClick={handleNextPage}
                  className="next-button-notification "
                >
                  Próximo
                  <ArrowRight2 size="12" color="white" />
                </Button>
              ) : (
                <div style={{ display: 'flex', marginTop: '6px', gap: '12px' }}>
                  <Button
                    appearance="secondary"
                    onClick={handlePreviousPage}
                    className="previous-button-notification"
                  >
                    <ArrowLeft2 size="12" color="#475467" />
                    Voltar
                  </Button>
                  <Button
                    appearance="primary"
                    onClick={handleClose}
                    className="close-button-notification"
                  >
                    Fechar
                  </Button>
                </div>
              )}
            </div>
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
