import { useEffect, useRef, useState } from 'react';
import CollapsableSelect from '../Inputs/CollapsableSelect';
import MultiSelect from '../Inputs/MultiSelect';
import { CustomCheckTree } from '../Inputs/CustomCheckTree';

const FilterWrapper = ({
  filter,
  context,
  filtersProps,
  entities,
  updateSelectedFilters,
  resultById,
  setClear,
  clear,
  filters,
  isHome,
  refreshRelator,
  requesting,
  isSearching,
  setClearForChildren,
}) => {
  const [delayedKey, setDelayedKey] = useState(null);
  const [selectedCount, setSelectedCount] = useState(0);
  const isClearingRef = useRef(false);

  // Função que conta se esta selecionado relatores ou não (para nao dar refresh
  // em momentos errados)
  const handleSelectionChange = (count, clearing = false) => {
    if (clearing) {
      isClearingRef.current = true;
    }
    setSelectedCount(count);
  };

  useEffect(() => {
    if (!requesting && selectedCount === 0 && !isClearingRef.current) {
      const timeout = setTimeout(() => {
        setDelayedKey(
          filter.type === 'multiple_autocomplete' ? refreshRelator : null,
        );
      }, 500);

      return () => clearTimeout(timeout);
    }

    if (isClearingRef.current) {
      isClearingRef.current = false;
    }
  }, [filter.type, refreshRelator, requesting, isSearching, selectedCount]);

  const renderFilterType = (filterType) => {
    switch (filterType) {
      case 'multiple_autocomplete':
        return (
          <MultiSelect
            label={filter.public_name}
            filter={filter}
            filterReference={filter.field_name}
            filtersProps={{
              ...filtersProps,
              onSelectionChange: handleSelectionChange,
            }}
            placeholder={'Filtre por relatores'}
            context={context}
            name={'relator'}
            updateSelectedFilters={updateSelectedFilters}
            resultById={resultById?.filter_by_relator}
            setClear={setClear}
            setClearForChildren={setClearForChildren}
            clear={clear}
          />
        );
      case 'multiple':
        return (
          <CustomCheckTree
            filters={filters}
            label={filter.public_name}
            filtersProps={filtersProps}
            context={context}
            filter={filter}
            filterReference={'entities'}
            labelKey="entities"
            options={entities}
            updateSelectedFilters={updateSelectedFilters}
            resultById={resultById?.entities}
            isHome={isHome}
            clear={clear}
          />
        );
      default:
        return null;
    }
  };

  return renderFilterType(filter.type) ? (
    <div
      className="tv-filter_group relator-entity-padding"
      key={filter.type === 'multiple_autocomplete' ? delayedKey : null}
    >
      {renderFilterType(filter.type)}
    </div>
  ) : null;
};

export default FilterWrapper;
