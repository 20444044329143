import './ResumeIAResult.css';
import resume_inteiro_teor from '../../../../../assets/svgs/resume-inteiro-teor-gray.svg';
import { Button } from 'rsuite';
import { useState } from 'react';
import { Dialog } from '../../../../../components/ui/dialog';
import { ResumeIABulkActionContainer } from '../../../../../components/ResumeInteiroTeorBulkAction/ResumeIABulkActionContainer/ResumeIABulkActionContainer';
import { PostHogSendResultPage } from '../../../../../utils/PostHogSendEvents';

export const ResumeIAResult = ({ countResults, firstCards }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="resume-ia-result-container">
        <div className="desc-resume-ia-result-container">
          <img src={resume_inteiro_teor} />
          <span>Resumir as 12 primeiras decisões</span>
        </div>
        <Button
          appearance="default"
          className="button-resume-ia-result"
          onClick={() => {
            setOpen(true);
            PostHogSendResultPage('ten_resume_ia');
          }}
        >
          Resumir agora
        </Button>
      </div>
      {open && (
        <Dialog>
          <ResumeIABulkActionContainer
            contextPage={'resume-in-result'}
            countResults={countResults}
            setOpen={setOpen}
            firstCards={firstCards}
            pageView={'pageresult'}
          />
          <div
            className="backgroundIAOpenBulkAction"
            onClick={() => setOpen(false)}
          />
        </Dialog>
      )}
    </>
  );
};
