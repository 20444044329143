import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Turivius } from '../../ui';
import { Col, Grid, Row } from 'rsuite';
import { Copy } from 'iconsax-react';
import resume_notification from '../../assets/svgs/resume-notification.svg';
import resume_inteiro_teor_purple from '../../assets/svgs/resume-inteiro-teor-purple.svg';

export const ResultSkeleton = ({ mobile, mdSize, mdOffset }) => {
  return (
    <div>
      <Col xs={24} sm={24} md={mdSize} mdOffset={mobile || !mdOffset ? 0 : 6}>
        <Turivius.TuriviusPanel
          className={'hoverable result-card'}
          style={{ zIndex: 0 }}
        >
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
        </Turivius.TuriviusPanel>
      </Col>
      <Col xs={24} sm={24} md={mdSize} mdOffset={mobile || !mdOffset ? 0 : 6}>
        <Turivius.TuriviusPanel
          className={'hoverable result-card'}
          style={{ zIndex: 0 }}
        >
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={50}
            width="100%"
            style={{ marginBottom: 6 }}
          />
        </Turivius.TuriviusPanel>
      </Col>
      {Array.from(Array(4).keys())?.map((i) => (
        <Col
          key={i}
          xs={24}
          sm={24}
          md={mdSize}
          mdOffset={mobile || !mdOffset ? 0 : 6}
        >
          <Turivius.TuriviusPanel style={{ height: 336, margin: '10px auto' }}>
            <div
              style={{
                width: '100%',
                height: '110px',
                display: 'flex',
                marginBottom: 10,
              }}
            >
              <Skeleton
                animation="wave"
                variant="circle"
                width={100}
                height={100}
                style={{ margin: '10px' }}
              />
              <div style={{ width: 'calc(100% - 110px)' }}>
                <Skeleton
                  animation="wave"
                  height={50}
                  width="96%"
                  style={{ margin: 'auto', marginBottom: 6 }}
                />
                <Skeleton
                  animation="wave"
                  height={50}
                  width="96%"
                  style={{ margin: 'auto', marginBottom: 6 }}
                />
              </div>
            </div>
            <Skeleton
              animation="wave"
              variant="rect"
              style={{ height: 130, marginRight: '2%' }}
            />
            <div style={{ width: '100%', height: 130, display: 'flex' }}>
              <Skeleton
                animation="wave"
                width="18%"
                height={50}
                style={{ marginRight: '2%', marginBottom: 6 }}
              />
              <Skeleton
                animation="wave"
                width="38%"
                height={50}
                style={{ marginRight: '2%', marginBottom: 6 }}
              />
              <Skeleton
                animation="wave"
                width="38%"
                height={50}
                style={{ marginRight: '2%', marginBottom: 6 }}
              />
            </div>
          </Turivius.TuriviusPanel>
        </Col>
      ))}
    </div>
  );
};

export const FiltersSkeleton = () => {
  return (
    <div className="sidebar-filters-list">
      <Grid fluid>
        <Row className="sidebar-filters-list-body">
          <Col xs={24} sm={24}>
            <Skeleton
              animation="wave"
              height={50}
              width="95%"
              style={{ margin: 'auto', marginBottom: 6 }}
            />
          </Col>
          {Array.from(Array(10).keys())?.map((i) => (
            <Col key={i} xs={24} sm={24}>
              <div style={{ width: '95%', height: '100px', margin: 'auto' }}>
                <Skeleton
                  animation="wave"
                  height={20}
                  width="100%"
                  style={{ margin: 'auto', marginBottom: 0 }}
                />
                <Skeleton
                  animation="wave"
                  height={80}
                  width="100%"
                  style={{ margin: 'auto', marginTop: -15 }}
                />
              </div>
            </Col>
          ))}
        </Row>
        <Row className="sidebar-filters-list-footer">
          <Col xs={12} sm={12}>
            <Skeleton
              animation="wave"
              height={50}
              width="100%"
              style={{ margin: 'auto' }}
            />
          </Col>
          <Col xs={12} sm={12}>
            <Skeleton
              animation="wave"
              height={50}
              width="100%"
              style={{ margin: 'auto' }}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export const SingleCardSkeleton = () => {
  return (
    <>
      {Array.from(Array(1).keys())?.map((i) => (
        <Col key={i} xs={24} sm={24}>
          <Turivius.TuriviusPanel style={{ height: 336, margin: '10px auto' }}>
            <div
              style={{
                width: '100%',
                height: '110px',
                display: 'flex',
                marginBottom: 10,
              }}
            >
              <Skeleton
                animation="wave"
                variant="circle"
                width={100}
                height={100}
                style={{ margin: '10px' }}
              />
              <div style={{ width: 'calc(100% - 110px)' }}>
                <Skeleton
                  animation="wave"
                  height={50}
                  width="96%"
                  style={{ margin: 'auto', marginBottom: 6 }}
                />
                <Skeleton
                  animation="wave"
                  height={50}
                  width="96%"
                  style={{ margin: 'auto', marginBottom: 6 }}
                />
              </div>
            </div>
            <Skeleton
              animation="wave"
              variant="rect"
              style={{ height: 130, marginRight: '2%' }}
            />
            <div style={{ width: '100%', height: 130, display: 'flex' }}>
              <Skeleton
                animation="wave"
                width="18%"
                height={50}
                style={{ marginRight: '2%', marginBottom: 6 }}
              />
              <Skeleton
                animation="wave"
                width="38%"
                height={50}
                style={{ marginRight: '2%', marginBottom: 6 }}
              />
              <Skeleton
                animation="wave"
                width="38%"
                height={50}
                style={{ marginRight: '2%', marginBottom: 6 }}
              />
            </div>
          </Turivius.TuriviusPanel>
        </Col>
      ))}
    </>
  );
};

export const CollectionSkeleton = () => {
  return (
    <>
      {Array.from(Array(4).keys())?.map((i) => (
        <Col key={i} xs={24} sm={24}>
          <Turivius.TuriviusPanel style={{ height: 336, margin: '10px auto' }}>
            <div
              style={{
                width: '100%',
                height: '110px',
                display: 'flex',
                marginBottom: 10,
              }}
            >
              <Skeleton
                animation="wave"
                variant="circle"
                width={100}
                height={100}
                style={{ margin: '10px' }}
              />
              <div style={{ width: 'calc(100% - 110px)' }}>
                <Skeleton
                  animation="wave"
                  height={50}
                  width="96%"
                  style={{ margin: 'auto', marginBottom: 6 }}
                />
                <Skeleton
                  animation="wave"
                  height={50}
                  width="96%"
                  style={{ margin: 'auto', marginBottom: 6 }}
                />
              </div>
            </div>
            <Skeleton
              animation="wave"
              variant="rect"
              style={{ height: 130, marginRight: '2%' }}
            />
            <div style={{ width: '100%', height: 130, display: 'flex' }}>
              <Skeleton
                animation="wave"
                width="18%"
                height={50}
                style={{ marginRight: '2%', marginBottom: 6 }}
              />
              <Skeleton
                animation="wave"
                width="38%"
                height={50}
                style={{ marginRight: '2%', marginBottom: 6 }}
              />
              <Skeleton
                animation="wave"
                width="38%"
                height={50}
                style={{ marginRight: '2%', marginBottom: 6 }}
              />
            </div>
          </Turivius.TuriviusPanel>
        </Col>
      ))}
    </>
  );
};

export const DrawerCollectionSkeleton = () => {
  return (
    <div className="card-result">
      <div className="content-card">
        <div className="title-modal-search-result">
          <Skeleton circle={true} height={20} width={20} />
          <Skeleton height={20} width={100} style={{ marginLeft: '10px' }} />
          <Skeleton height={20} width={40} style={{ marginLeft: '10px' }} />
        </div>
        <div className="data-search-results">
          <Skeleton height={15} width={15} />
          <Skeleton height={15} width={150} style={{ marginLeft: '10px' }} />
        </div>
      </div>

      <div className="text-info">
        <Skeleton height={20} width={200} />
        <Skeleton count={5} />
      </div>

      <div className="buttons">
        <div className="copy-reload-buttons">
          <Skeleton height={40} width={80} />
          <Skeleton height={40} width={40} style={{ marginLeft: '10px' }} />
        </div>
        <div className="copy-reload-buttons">
          <Skeleton height={15} width={150} />
          <Skeleton height={40} width={40} style={{ marginLeft: '10px' }} />
          <Skeleton height={40} width={40} style={{ marginLeft: '10px' }} />
        </div>
      </div>
    </div>
  );
};

export const ResumeInteiroTeorSkeleton = () => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          marginLeft: '8px',
          marginTop: '16px',
          marginBottom: '16px',
          gap: '8px',
        }}
      >
        <Skeleton
          animation="wave"
          height={17}
          width={151}
          style={{
            marginBottom: 6,
            borderRadius: '99px',
            backgroundColor: '#D0D5DD',
          }}
        />
        <Skeleton
          animation="wave"
          height={17}
          width={151}
          style={{
            marginBottom: 6,
            borderRadius: '99px',
            backgroundColor: '#F2F4F7',
          }}
        />
      </div>
      <Col style={{ width: '704px' }}>
        <Turivius.TuriviusPanel
          className={'hoverable result-card'}
          style={{ zIndex: 0, justifyItems: 'center' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Skeleton
              animation="pulse"
              variant="circle"
              width={17}
              height={17}
              style={{ margin: '10px 10px 10px 0px', backgroundColor: 'blue' }}
            />
            <Skeleton
              animation="wave"
              height={17}
              width={600}
              style={{
                marginBottom: 6,
                borderRadius: '99px',
                backgroundColor: '#D0D5DD',
              }}
            />
            <Copy
              size="28"
              variant="Linear"
              color="#475467"
              style={{
                border: ' 1px solid #E4E7EC',
                borderRadius: '4px',
                marginLeft: 'auto',
                transform: 'rotate(180deg)',
                padding: '6px',
              }}
            />
          </div>
          <Skeleton
            animation="wave"
            height={18}
            width={'98%'}
            style={{
              marginBottom: 6,
              borderRadius: '99px',
              backgroundColor: '#F2F4F7',
            }}
          />
          <Skeleton
            animation="wave"
            height={18}
            width={'98%'}
            style={{
              marginBottom: 6,
              borderRadius: '99px',
              backgroundColor: '#F2F4F7',
            }}
          />
          <Skeleton
            animation="wave"
            height={18}
            width={'98%'}
            style={{
              marginBottom: 6,
              borderRadius: '99px',
              backgroundColor: '#F2F4F7',
            }}
          />
          <Skeleton
            animation="wave"
            height={18}
            width={'98%'}
            style={{
              marginBottom: 6,
              borderRadius: '99px',
              backgroundColor: '#F2F4F7',
            }}
          />
          <Skeleton
            animation="wave"
            height={18}
            width={'98%'}
            style={{
              marginBottom: 6,
              borderRadius: '99px',
              backgroundColor: '#F2F4F7',
            }}
          />
          <Skeleton
            animation="wave"
            height={18}
            width={'98%'}
            style={{
              marginBottom: 6,
              borderRadius: '99px',
              backgroundColor: '#F2F4F7',
            }}
          />
          <Skeleton
            animation="wave"
            height={18}
            width={416}
            style={{
              marginBottom: 26,
              borderRadius: '99px',
              backgroundColor: '#F2F4F7',
            }}
          />
          <Skeleton
            animation="wave"
            height={1}
            width={'98%'}
            style={{
              marginBottom: 16,
              border: '1px solid #E4E7EC',
            }}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Skeleton
              animation="pulse"
              variant="circle"
              width={17}
              height={17}
              style={{ margin: '10px 10px 10px 0px', backgroundColor: 'blue' }}
            />
            <Skeleton
              animation="wave"
              height={17}
              width={236}
              style={{
                marginBottom: 6,
                borderRadius: '99px',
                backgroundColor: '#D0D5DD',
              }}
            />
            <Copy
              size="28"
              variant="Linear"
              color="#475467"
              style={{
                border: ' 1px solid #E4E7EC',
                borderRadius: '4px',
                marginLeft: 'auto',
                transform: 'rotate(180deg)',
                padding: '6px',
              }}
            />
          </div>
          <Skeleton
            animation="wave"
            height={18}
            width={'98%'}
            style={{
              marginBottom: 6,
              borderRadius: '99px',
              backgroundColor: '#F2F4F7',
            }}
          />
          <Skeleton
            animation="wave"
            height={18}
            width={'98%'}
            style={{
              marginBottom: 6,
              borderRadius: '99px',
              backgroundColor: '#F2F4F7',
            }}
          />
          <Skeleton
            animation="wave"
            height={18}
            width={'98%'}
            style={{
              marginBottom: 6,
              borderRadius: '99px',
              backgroundColor: '#F2F4F7',
            }}
          />
          <Skeleton
            animation="wave"
            height={18}
            width={'98%'}
            style={{
              marginBottom: 6,
              borderRadius: '99px',
              backgroundColor: '#F2F4F7',
            }}
          />
          <Skeleton
            animation="wave"
            height={18}
            width={'98%'}
            style={{
              marginBottom: 6,
              borderRadius: '99px',
              backgroundColor: '#F2F4F7',
            }}
          />
          <Skeleton
            animation="wave"
            height={18}
            width={'98%'}
            style={{
              marginBottom: 6,
              borderRadius: '99px',
              backgroundColor: '#F2F4F7',
            }}
          />
          <Skeleton
            animation="wave"
            height={18}
            width={416}
            style={{
              marginBottom: 26,
              borderRadius: '99px',
              backgroundColor: '#F2F4F7',
            }}
          />
          <Skeleton
            animation="wave"
            height={1}
            width={'98%'}
            style={{
              marginBottom: 16,
              border: '1px solid #E4E7EC',
            }}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Skeleton
              animation="pulse"
              variant="circle"
              width={17}
              height={17}
              style={{ margin: '10px 10px 10px 0px', backgroundColor: 'blue' }}
            />
            <Skeleton
              animation="wave"
              height={17}
              width={236}
              style={{
                marginBottom: 6,
                borderRadius: '99px',
                backgroundColor: '#D0D5DD',
              }}
            />
            <Copy
              size="28"
              variant="Linear"
              color="#475467"
              style={{
                border: ' 1px solid #E4E7EC',
                borderRadius: '4px',
                marginLeft: 'auto',
                transform: 'rotate(180deg)',
                padding: '6px',
              }}
            />
          </div>
          <Skeleton
            animation="wave"
            height={18}
            width={'98%'}
            style={{
              marginBottom: 6,
              borderRadius: '99px',
              backgroundColor: '#F2F4F7',
            }}
          />
          <Skeleton
            animation="wave"
            height={18}
            width={'98%'}
            style={{
              marginBottom: 6,
              borderRadius: '99px',
              backgroundColor: '#F2F4F7',
            }}
          />
          <Skeleton
            animation="wave"
            height={18}
            width={'98%'}
            style={{
              marginBottom: 6,
              borderRadius: '99px',
              backgroundColor: '#F2F4F7',
            }}
          />
          <Skeleton
            animation="wave"
            height={18}
            width={'98%'}
            style={{
              marginBottom: 6,
              borderRadius: '99px',
              backgroundColor: '#F2F4F7',
            }}
          />
          <Skeleton
            animation="wave"
            height={18}
            width={'98%'}
            style={{
              marginBottom: 6,
              borderRadius: '99px',
              backgroundColor: '#F2F4F7',
            }}
          />
          <Skeleton
            animation="wave"
            height={18}
            width={'98%'}
            style={{
              marginBottom: 6,
              borderRadius: '99px',
              backgroundColor: '#F2F4F7',
            }}
          />
          <Skeleton
            animation="wave"
            height={18}
            width={416}
            style={{
              marginBottom: 26,
              borderRadius: '99px',
              backgroundColor: '#F2F4F7',
            }}
          />
          <Skeleton
            animation="wave"
            height={1}
            width={'98%'}
            style={{
              marginBottom: 16,
              border: '1px solid #E4E7EC',
            }}
          />
        </Turivius.TuriviusPanel>
      </Col>
    </div>
  );
};

export const ResumeInteiroTeorNotification = () => {
  return (
    <div
      style={{
        backgroundColor: '#F5FAFF',
        width: '352px',
        height: '201px',
        borderRadius: '16px',
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'center',
        marginTop: '8px',
        position: 'relative',
      }}
    >
      <div
        style={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          backgroundColor: '#ffffff',
          width: '281px',
          height: '183px',
          boxShadow: '5px 0px 3px 0px #0000000F, 0px -5px 2px 0px #0000000A',
          position: 'absolute',
          right: '20px',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <img
          src={resume_inteiro_teor_purple}
          style={{
            width: '18px',
            height: '21px',
            marginRight: '14px',
            marginTop: '20px',
          }}
        />
        <div
          style={{
            marginTop: '20px',
          }}
        >
          <Skeleton
            animation="wave"
            height={12}
            width={82}
            style={{
              borderRadius: '999px',
              backgroundColor: '#DDDDDD',
            }}
          />
          <Skeleton
            animation="wave"
            height={12}
            width={195}
            style={{
              borderRadius: '999px',
              backgroundColor: '#EBE2EC',
            }}
          />
        </div>
      </div>
      <div
        style={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          backgroundColor: '#ffffff',
          width: '293px',
          height: '107px',
          boxShadow: '5px 0px 3px 0px #0000000F, 0px -5px 2px 0px #0000000A',
          position: 'absolute',
          left: '20px',
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img
          src={resume_inteiro_teor_purple}
          style={{
            width: '18px',
            height: '21px',
            marginRight: '14px',
            marginBottom: '44px',
          }}
        />
        <div>
          <Skeleton
            animation="wave"
            height={12}
            width={82}
            style={{
              borderRadius: '999px',
              backgroundColor: '#DDDDDD',
            }}
          />
          <Skeleton
            animation="wave"
            height={12}
            width={195}
            style={{
              borderRadius: '999px',
              backgroundColor: '#EBE2EC',
            }}
          />
          <img
            src={resume_notification}
            style={{
              width: '163px',
              height: '36px',
              marginTop: '14px',
              marginBottom: '-10px',
            }}
          />
        </div>
      </div>
    </div>
  );
};
