import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Nav, Sidebar } from 'rsuite';
import { Sidenav as RSSidenav } from 'rsuite';
import './style.scss';
import { TURIVIUS_VERSION } from '../../app/App';
import { ArrowLeft2, ArrowRight2 } from 'iconsax-react';
import { Turivius } from '../../ui';
import { Whisper, Tooltip } from 'rsuite';
import LogoLetraAzulEscuro from '../../assets/svgs/Logo-Letra-Azul-Escuro.svg';

const Sidenav = ({
  defaultOpenKeys,
  handleSelectNavItem,
  header,
  footer,
  navItems,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [routeActive, setRouteActive] = useState('');
  const { pathname } = useLocation();

  const [isPesquisaPage, setIsPesquisaPage] = useState(false);
  const [showLogo, setShowLogo] = useState(isOpen);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [hoveredFooter, setHoveredFooter] = useState(null);

  useEffect(() => {
    setIsPesquisaPage(pathname === '/pesquisa');
    if (pathname === '/pesquisa/resultados') {
      setIsOpen(false);
    }
    setRouteActive(pathname);
  }, [pathname]);

  useEffect(() => {
    localStorage.setItem('menu_open', isOpen);
    window.dispatchEvent(new Event('menuOpen'));
  }, [isOpen]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLogo(isOpen);
    }, 100);
    return () => clearTimeout(timeout);
  }, [isOpen]);

  const sidebarClass = isPesquisaPage
    ? 'contente-sidebar pesquisa'
    : 'contente-sidebar resultados';

  return (
    <>
      {isOpen ? (
        <div className={sidebarClass}>
          <Sidebar className="turivius-sidebar">
            <RSSidenav
              defaultOpenKeys={defaultOpenKeys}
              expanded={true}
              onSelect={handleSelectNavItem}
              className="turivius-sidenav"
            >
              <Whisper
                placement="right"
                trigger="hover"
                speaker={<Tooltip>Fechar Menu</Tooltip>}
              >
                <Button
                  className="hidden-menu"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <ArrowLeft2 color="#344054" />
                </Button>
              </Whisper>
              <RSSidenav.Header>
                <Button
                  onClick={() => header.onClick()}
                  className="sidenav-header_logo"
                >
                  {showLogo && (
                    <img
                      src="/Logo_branco.png"
                      alt="Logo Turivius"
                      style={{ display: isOpen ? 'block' : 'none' }}
                    />
                  )}
                </Button>
              </RSSidenav.Header>
              <RSSidenav.Body>
                <div className="sidenav-container">
                  <div className="sidenav-body">
                    {navItems?.map((item, index) => (
                      <div key={index}>
                        <Nav>
                          {item.products?.map((i, index) => {
                            const isActive = i.route === routeActive;
                            const isHovered =
                              hoveredItem === `${index}-${item.title}`;

                            const iconToShow =
                              isHovered && i.icon_hover_mouse
                                ? i.icon_hover_mouse
                                : isActive
                                  ? i.icon_hover
                                  : i.icon;

                            return (
                              <Nav.Item
                                id={i.id}
                                key={index}
                                onClick={i.onClick}
                                onMouseEnter={() =>
                                  setHoveredItem(`${index}-${item.title}`)
                                }
                                onMouseLeave={() => setHoveredItem(null)}
                                className={
                                  isActive
                                    ? 'turivius-menu_option flex'
                                    : 'flex'
                                }
                              >
                                {iconToShow}
                                {i.name}
                                {i?.show ? (
                                  <div
                                    className="px-2 text-white font-semibold rounded-full"
                                    style={{
                                      fontSize: '8px',
                                      marginLeft: '8px',
                                      marginRight: '0px',
                                      padding: '5px 6px 3px',
                                    }}
                                  >
                                    {' '}
                                  </div>
                                ) : (
                                  <div
                                    className="px-2 bg-[#101828] text-white font-semibold rounded-full"
                                    style={{
                                      fontSize: '8px',
                                      marginLeft: '4px',
                                      marginRight: '0px',
                                      padding: '5px 6px 3px',
                                    }}
                                  >
                                    PRO
                                  </div>
                                )}
                              </Nav.Item>
                            );
                          })}
                        </Nav>
                      </div>
                    ))}
                  </div>
                  <div style={{ marginBottom: '-14px' }}>
                    <div className="line-footer"></div>
                    <div className="sidenav-footer">
                      <Nav className="turivius-module">
                        {footer?.map((item, index) => {
                          const isHovered = hoveredFooter === index;
                          const iconToShow =
                            isHovered && item.icon_hover_mouse
                              ? item.icon_hover_mouse
                              : item.icon;

                          return (
                            <Nav.Item
                              key={index}
                              onClick={item.onClick}
                              onMouseEnter={() => setHoveredFooter(index)}
                              onMouseLeave={() => setHoveredFooter(null)}
                              className="nav-item_footer"
                              style={{
                                width: 'fit-content',
                                height: 'fit-content',
                              }}
                            >
                              <p className="turivius-module_name flex items-center">
                                {iconToShow}
                                {item.children}
                              </p>
                            </Nav.Item>
                          );
                        })}
                      </Nav>
                    </div>
                    <div className="text-white pl-4 text-xs ">
                      <br />v{TURIVIUS_VERSION}
                    </div>
                  </div>
                </div>
              </RSSidenav.Body>
            </RSSidenav>
          </Sidebar>
        </div>
      ) : (
        <div className={`${sidebarClass} close`}>
          <Sidebar className="turivius-sidebar">
            <RSSidenav
              defaultOpenKeys={defaultOpenKeys}
              expanded={true}
              onSelect={handleSelectNavItem}
              className="turivius-sidenav"
            >
              <Whisper
                placement="right"
                trigger="hover"
                speaker={<Tooltip>Abrir menu</Tooltip>}
              >
                <Button
                  className="hidden-menu-close"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <ArrowRight2 color="#344054" />
                </Button>
              </Whisper>
              <RSSidenav.Header className="close">
                <Button
                  onClick={() => header.onClick()}
                  className="sidenav-header_logo_close"
                >
                  {!showLogo && (
                    <img
                      src={LogoLetraAzulEscuro}
                      alt="Logo Turivius"
                      style={{ display: isOpen ? 'none' : 'block' }}
                    />
                  )}
                </Button>
              </RSSidenav.Header>
              <RSSidenav.Body>
                <div className="sidenav-container">
                  <div className="sidenav-body">
                    {navItems?.map((item, index) => (
                      <div key={index}>
                        <Nav>
                          {item.products?.map((i, index) => (
                            <Nav.Item
                              id={i.id}
                              key={index}
                              onClick={i.onClick}
                              className={
                                i?.route === routeActive
                                  ? 'turivius-menu_option_close'
                                  : ''
                              }
                            >
                              <Turivius.TuriviusTooltip
                                trigger={'hover'}
                                message={i.name}
                                placement={'right'}
                              >
                                {i.route !== routeActive
                                  ? i.icon
                                  : i.icon_hover}
                              </Turivius.TuriviusTooltip>
                            </Nav.Item>
                          ))}
                        </Nav>
                      </div>
                    ))}
                  </div>

                  <div>
                    <div className="line-footer-closed"></div>
                    <div className="sidenav-footer">
                      <Nav className="turivius-module">
                        {footer?.map((item, index) => (
                          <Nav.Item
                            key={index}
                            onClick={item.onClick}
                            className="nav-item_footer"
                          >
                            <Turivius.TuriviusTooltip
                              trigger={'hover'}
                              message={item.children}
                              placement={'right'}
                            >
                              <p className="turivius-module_name">
                                {item.icon}
                              </p>
                            </Turivius.TuriviusTooltip>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </div>
                    {/* <div className="text-white pl-5 text-xs "></div> // TODO: version letra branca */}
                    <div className="text-white pl-5 text-xs ">
                      <br />v{TURIVIUS_VERSION}
                    </div>
                  </div>
                </div>
              </RSSidenav.Body>
            </RSSidenav>
          </Sidebar>
        </div>
      )}
    </>
  );
};

export default Sidenav;
