import { ResponsiveHeatMap } from '@nivo/heatmap';
import { BasicTooltip } from '@nivo/tooltip';
import { useEffect, useState } from 'react';

const config = {
  valueFormat: '>-.2~s',
  labelColumnPosition: 'middle',
  legendAlign: 'start',
  legendAnchor: 'bottom',
  schemeType: 'diverging',
  schemeColor: 'blues',
};

const Heatmap = ({ id, title, data, axisRightlegend, legend, emptyColor }) => {
  const [showData, setShowData] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowData(true);
    }, 100);

    return () => clearTimeout(timeout);
  }, []);
  return (
    <>
      <p className="chart-title">
        <small style={{ fontSize: '95%' }}>{title}</small>
      </p>

      <div
        id={id}
        data-testid={`bar-chart-${id}`}
        className={`turivius-heatmap-chart`}
        style={{ width: '100%', height: 400 }}
      >
        {showData && (
          <ResponsiveHeatMap
            data={data}
            margin={{ top: 60, right: 0, bottom: 60, left: 130 }}
            axisTop={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendOffset: 46,
            }}
            axisRight={null}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: axisRightlegend,
              legendPosition: config.labelColumnPosition,
              legendOffset: -125,
            }}
            colors={{
              type: config.schemeType,
              scheme: config.schemeColor,
              minValue: -100000,
              maxValue: 100000,
              divergeAt: 0.8,
            }}
            borderRadius={8}
            borderColor={{
              from: 'color',
              modifiers: [['darker', '0']],
            }}
            labelTextColor={{
              from: 'color',
              modifiers: [['darker', '3']],
            }}
            emptyColor={emptyColor}
            legends={[
              {
                anchor: config.legendAnchor,
                translateX: 0,
                translateY: 30,
                length: 400,
                thickness: 8,
                direction: 'row',
                tickPosition: 'after',
                tickSize: 3,
                tickSpacing: 4,
                tickOverlap: false,
                tickFormat: '>-.2s',
                title: legend,
                titleAlign: config.legendAlign,
                titleOffset: 5,
              },
            ]}
            tooltip={({ cell }) => {
              const dateData = cell.data.x.split('-');
              let tooltipDate = dateData[0];
              if (dateData.length == 2)
                tooltipDate = dateData[1] + '/' + tooltipDate;
              return (
                <BasicTooltip
                  id={
                    <span>
                      {cell.serieId}: {cell.data.y} decisões em {tooltipDate}
                    </span>
                  }
                  enableChip={true}
                  color={cell.color}
                />
              );
            }}
          />
        )}
      </div>
    </>
  );
};

export default Heatmap;
