import { useState, useEffect } from 'react';
import { Button, SpinningLoader } from '../../../../../ds-components';
import { Add, ArrowLeft2, CloseCircle } from 'iconsax-react';
import { Input, Message, Modal, toaster } from 'rsuite';
import { requester } from '../../../../../requesters';
import CustomerXTracking from '../../../../../utils/CustomerXTracking';
import './CreateCollection.css';

import { PostHogSendCollection } from '../../../../../utils/PostHogSendEvents';

const CreateCollection = ({ getCollection, className, fullWidth }) => {
  const profile = JSON.parse(localStorage.getItem('profile'));

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [titleCollection, setTitleCollection] = useState('');
  const [descriptionCollection, setDescriptionCollection] = useState('');

  const handleModal = () => setModal(!modal);

  function createCollection() {
    if (titleCollection.length === 0) {
      alert('Titulo é obrigatorio');
    } else {
      setLoading(true);

      requester.collections
        .createCollection(profile.id, titleCollection, descriptionCollection)
        .then((response) => {
          getCollection();
          handleModal();
          cleanData();
          PostHogSendCollection('create_collection', 'collectionpage'); // POSTHOG_NEW
          if (descriptionCollection != '') {
            PostHogSendCollection('add_description_collections'); // POSTHOG_NEW
          }
          toaster.push(
            <Message
              showIcon
              type="success"
              duration={4000}
              style={{ marginBottom: '50px' }}
            >
              Coleção {response.name} foi criada com sucesso!
            </Message>,
          );

          try {
            CustomerXTracking('action', 'create_collection');
          } catch {}
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  function cleanData() {
    setTitleCollection('');
    setDescriptionCollection('');
  }

  useEffect(() => {
    const handleGlobalKeyDown = (event) => {
      if (event.key === 'Enter' && modal) {
        createCollection();
      }
    };

    if (modal) {
      window.addEventListener('keydown', handleGlobalKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleGlobalKeyDown);
    };
  }, [modal, titleCollection, descriptionCollection]);

  return (
    <>
      <Button
        fullWidth={fullWidth}
        className={`custom-create-button ${className ? className + ' create-new-collection-btn' : 'create-new-collection-btn'}`}
        onClick={handleModal}
        size="sm"
      >
        + Criar Coleção
      </Button>

      <Modal className="tv-modal-default" open={modal} onClose={handleModal}>
        <Modal.Header
          closeButton={false}
          className="tv-modal-default_header"
          style={{
            display: 'flex',
          }}
        >
          <h6
            className="tv-modal-default_header_title"
            style={{ marginRight: 'auto' }}
          >
            Nova Coleção
          </h6>
          <div style={{ textAlign: 'end' }}>
            <CloseCircle
              size="24"
              color="#98A2B3"
              onClick={handleModal}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </Modal.Header>
        <Modal.Body className="tv-modal-default_body">
          <label className="tv-modal-default_body_label">Título *</label>
          <Input
            className="tv-modal-default_body_input"
            placeholder="Insira um titulo para coleção"
            value={titleCollection}
            onChange={setTitleCollection}
          />

          <label className="tv-modal-default_body_label">Descrição</label>
          <Input
            className="tv-modal-default_body_input"
            placeholder="Insira uma descrição para coleção"
            value={descriptionCollection}
            onChange={setDescriptionCollection}
          />
        </Modal.Body>
        <Modal.Footer className="tv-modal-default_footer">
          <Button
            size="sm"
            fullWidth
            icon={<ArrowLeft2 />}
            appearance="secondary"
            onClick={handleModal}
          >
            Cancelar
          </Button>
          <Button
            size="sm"
            appearance="blue900"
            fullWidth
            icon={<Add />}
            onClick={createCollection}
          >
            {loading ? (
              <SpinningLoader color="white" size="sm" />
            ) : (
              'Criar coleção'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateCollection;
