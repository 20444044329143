import { useEffect, useState } from 'react';
import { FlexboxGrid } from 'rsuite';
import { requester } from '../../../requesters';
import { withRouter } from 'react-router-dom';
import { Creators as ProfilesActions } from '../../../store/ducks/profiles';
import { listing } from '../../../config/casos-uso';
import { bindActionCreators } from 'redux';
import CaseCard from '../components/CaseCard';

import { connect } from 'react-redux';
import './JurimetriaTrabalhista.scss';
import FlagSystem from '../../../components/FlagSystem';

const JurimetriaTrabalhista = (props) => {
  const [entities, setEntities] = useState([]);
  const [topics, setTopics] = useState([]);
  const [cardPermission, setCardPermission] = useState(0);

  const getAssuntosJurimetria = () => {
    requester.jurimetrics
      .getAssuntos()
      .then((response) => {
        setTopics(response);
      })
      .catch(console.error);
  };

  const checkPermission = async () => {
    requester.segmentation
      .validateAction('search_labor_jurimetry')
      .then((response) => {
        if (response.status == 400 || response.status == 403) {
          setCardPermission(response.message);
          try {
            localStorage.setItem(
              'actions_permission',
              JSON.stringify({ search_labor_jurimetry: response.message }),
            );
          } catch {
            localStorage.setItem('actions_permission', JSON.stringify({}));
          }
        } else {
          setCardPermission(response);
          try {
            localStorage.setItem(
              'actions_permission',
              JSON.stringify({ search_labor_jurimetry: response }),
            );
          } catch {
            localStorage.setItem('actions_permission', JSON.stringify({}));
          }
        }
      });
  };

  const getEntities = () => {
    requester.jurimetrics.getEstados().then(setEntities).catch(console.log);
  };

  useEffect(() => {
    document.title = 'Jurimetria Trabalhista';
    getEntities();
    getAssuntosJurimetria();
    checkPermission();
  }, ['']);

  return (
    <div>
      <FlexboxGrid justify="center">
        <FlexboxGrid.Item colspan={24}>
          <FlagSystem current="jurimetrics-trabalhist" />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item colspan={22} justify="center">
          <div className="search-jurimetrics">
            <h6>Tome decisões estratégicas com dados estatísticos</h6>
            <p>
              Selecione um dos campos de busca e analise dashboards de
              jurimetria dos Tribunais
            </p>
          </div>

          <div className="card-case-grid">
            {listing?.map((uc, index) => (
              <CaseCard
                key={index}
                info={uc}
                topics={topics || []}
                entities={entities}
                permissions={props.permissions['trabalhista']}
                cardPermission={cardPermission}
              />
            ))}
          </div>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  permissions: state.profiles.permissions,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(Object.assign(ProfilesActions), dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(JurimetriaTrabalhista));
