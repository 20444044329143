import './ResumeIADecision.css';

import aiicon from '../../../../../../assets/svgs/AI-icon2.svg';
import {
  Book1,
  Judge,
  Message,
  Firstline,
  Link1,
  TableDocument,
} from 'iconsax-react';

const ResumeIADecision = ({ markdownText }) => {
  return (
    <div style={{ height: '640px' }}>
      <div className="title-generic-singular">
        {' '}
        <img
          src={aiicon}
          style={{
            width: '16px',
            height: '16px',
          }}
        />
        Análise
      </div>
      <div className="resume-result-desicion-singular">
        {markdownText.data.summary_fatos}
      </div>
      <div className="title-generic-singular">
        {' '}
        <Judge size="16" color="#292D32" />
        Decisão Final
      </div>
      <div className="resume-result-desicion-singular">
        {markdownText.data.summary_decisao}
      </div>
      <div className="title-generic-singular">
        {' '}
        <TableDocument size="16" color="#292D32" />
        Assuntos tratados
      </div>
      <div className="resume-result-desicion-singular">
        <ul
          style={{
            listStyleType: 'disc',
            paddingLeft: '4px',
            fontSize: '16px',
            display: 'flex', // Faz os itens ficarem na mesma linha
            flexWrap: 'wrap', // Permite que quebrem de forma natural se necessário
            gap: '10px', // Espaço entre os itens
          }}
        >
          {markdownText?.data.summary_topics
            ?.split('\n')
            .filter((text) => text.trim() !== '') // Remove linhas vazias
            .map((theText, index) => (
              <li key={index} style={{ display: 'inline-block' }}>
                • {theText}
              </li>
            ))}
        </ul>
      </div>
      <div className="title-generic-singular">
        {' '}
        <Book1 size="16" color="#292D32" />
        Legislação citada
      </div>
      <div className="resume-result-desicion-singular">
        <ul
          style={{
            listStyleType: 'disc',
            paddingLeft: '4px',
            fontSize: '16px',
            display: 'flex', // Faz os itens ficarem na mesma linha
            flexWrap: 'wrap', // Permite que quebrem de forma natural se necessário
            gap: '10px', // Espaço entre os itens
          }}
        >
          {markdownText?.data.summary_law
            ?.split('\n')
            .filter((text) => text.trim() !== '') // Remove linhas vazias
            .map((theText, index) => (
              <li key={index} style={{ display: 'inline-block' }}>
                • {theText}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default ResumeIADecision;
