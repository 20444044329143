import { useMemo, useEffect } from 'react';
import './styles.css';

export const CheckboxList = ({
  searchTerm,
  setRecursiveChildrenOpen,
  setIsMouseEntered,
  selectedRecurse,
  filtersProps,
  groupData,
  setIsCheckedsRecursives,
  isCheckedsRecursives,
  clear,
}) => {
  const filteredData = useMemo(() => {
    if (!searchTerm) return groupData[selectedRecurse] || [];
    return (groupData[selectedRecurse] || []).filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [searchTerm, selectedRecurse, groupData]);

  const handleCheckboxChange = (id) => {
    setIsCheckedsRecursives((prevState) => {
      const newSelections = prevState.includes(id)
        ? prevState.filter((item) => item !== id)
        : [...prevState, id];
      return newSelections;
    });
  };

  const updateUrlWithSelectedEntities = (selected) => {
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);

    const storedFilters = JSON.parse(localStorage.getItem('filters')) || {};
    storedFilters.appeals = selected;

    params.set('filters', JSON.stringify(storedFilters));
    const queryParams = decodeURIComponent(params.toString());
    if (window.location.pathname == '/pesquisa/resultados') {
      window.history.replaceState({}, '', `${url.pathname}?${queryParams}`);
    }
  };

  useEffect(() => {
    const savedSelections = JSON.parse(localStorage.getItem('filters')) || {};
    const savedItems = savedSelections.appeals || [];

    if (!isCheckedsRecursives || isCheckedsRecursives.length === 0) {
      setIsCheckedsRecursives(savedItems);
    }
  }, []);

  useEffect(() => {
    updateUrlWithSelectedEntities(setIsCheckedsRecursives);
    filtersProps.setTmpFilters('appeals', isCheckedsRecursives);
  }, [isCheckedsRecursives]);

  useEffect(() => {
    if (clear) filtersProps.setTmpFilters('appeals', isCheckedsRecursives);
  }, [clear]);

  useEffect(() => {
    filtersProps.setTmpFilters('appeals', isCheckedsRecursives);
    const savedSelections = JSON.parse(localStorage.getItem('filters')) || {};
    savedSelections.appeals = isCheckedsRecursives;
    localStorage.setItem('filters', JSON.stringify(savedSelections));
  }, [isCheckedsRecursives]);

  return (
    <div
      className="checkbox-list-container"
      onMouseEnter={() => setRecursiveChildrenOpen(true)}
      onMouseLeave={() => {
        setIsMouseEntered(false);
        setRecursiveChildrenOpen(false);
      }}
    >
      {filteredData.map((item) => (
        <div key={item.id} className="checkbox-item">
          <label>
            <input
              type="checkbox"
              checked={(isCheckedsRecursives || []).includes(item.id)}
              onChange={() => handleCheckboxChange(item.id)}
            />
            {item.name}
          </label>
          {/* <span className="item-count">+ 12</span>  Count */}
        </div>
      ))}
    </div>
  );
};
