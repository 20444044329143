import { Button } from '../../../../../ds-components';
import resumeInteiroTeorIcon from '../../../../../assets/svgs/resume-inteiro-teor-black.svg';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../../../components/ui/dialog';
import { FloatingUI } from '../../../../../components/ui/float';
import documentNormalIcon from '../../../../../assets/svgs/document-normal-white.svg';
import {
  ArchiveAdd,
  ArchiveTick,
  Copy,
  Dislike,
  ExportSquare,
  Like1,
  Link1,
  TickCircle,
} from 'iconsax-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { wait } from '../../../../../utils/wait';
import { cn } from '../../../../../lib/utils';
import { Tooltip, Whisper } from 'rsuite';
import {
  useFetchEntities,
  useFetchFilters,
  useFetchSummary,
  useSummaryFeedback,
} from '../../../../../api/cards';
import { toast } from 'react-toastify';
import { posthog } from '../../../../../requesters';
import { Profile } from '../../../../../@types/profile';
import { useLocation } from 'react-router-dom';
import ResumeIADecision from '../../../../collections/components/Collection/ModalCollection/components/ResumeIADecision';
import './ResumeInteiroTeor.scss';

import { PostHogSendResultPage } from '../../../../../utils/PostHogSendEvents';
import { UsefetchResumeIA } from '../../../../../api/resume-ia';
import { ResumeIAResponse } from '../../../../../api/resume-ia/types';
import SaveCardToCollectionButton from './SaveCardToCollectionButton';

type ResumeInteiroTeorProps = {
  card: {
    id: string;
    name: string;
    entity: {
      id: number;
    };
    sumarizable: boolean;
    content: any;
    dataJul: string;
    dataPub: string;
    data_jul: string;
    data_pub: string;
    data_publicacao_date: string;
    relator: string;
    url: string;
  };
  pageView?: string;
  cardForResumo?: [];
  query?: string;
  theAllCollectionsInOne?: [];
  isItInACollection?: boolean;
};

export const ResumeInteiroTeor = ({
  card: {
    id,
    name,
    entity: { id: entityId },
    sumarizable: summarizable,
    content,
    dataJul,
    dataPub,
    data_jul,
    data_pub,
    data_publicacao_date,
    relator,
    url,
  },
  pageView,
  cardForResumo,
  query,
  theAllCollectionsInOne,
  isItInACollection,
}: ResumeInteiroTeorProps) => {
  const { data } = useFetchFilters();
  const { data: fetchEntitiesData } = useFetchEntities();
  // const {
  //   data: fetchSummaryData,
  //   mutate: mutateSumary,
  //   isPending: isPendingSumary,
  //   isSuccess: isSuccessSumary,
  //   isError: isErrorSumary,
  // } = useFetchSummary();

  const {
    data: fetchResumeIAData,
    mutate: mutateResumeIA,
    isPending: isPendingResumeIA,
    isSuccess: isSuccessResumeIA,
    isError: isErrorResumeIA,
  } = UsefetchResumeIA();
  const { mutate: mutateSummaryFeedback } = useSummaryFeedback();

  const { pathname } = useLocation();
  const isInMonitoring = useMemo(
    () => pathname.includes('/monitoramento'),
    [pathname],
  );

  const userId = useMemo(() => localStorage.getItem('user') as string, []);
  const profile = useMemo(
    () => JSON.parse(localStorage.getItem('profile') as string) as Profile,
    [],
  );

  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [textToCopy, setTextToCopy] = useState<string | undefined>('');
  const [fullCopied, setFullCopied] = useState(false);
  const [like, setLike] = useState<'like' | 'unlike' | null>(null);
  const [dataResumeIA, setDataResumeIA] = useState<ResumeIAResponse | null>(
    null,
  );

  const theOptions = useMemo(
    () => data?.find((filter) => filter.public_name === 'Selecione os órgãos'),
    [data],
  );

  const theText = useMemo(
    () =>
      name +
      '\n\n' +
      'As decisões falam sobre:' +
      '\n\n' +
      dataResumeIA?.data?.summary_fatos +
      '\n\n\n' +
      'Decisão Final:' +
      '\n\n' +
      dataResumeIA?.data?.summary_decisao,
    [name, fetchResumeIAData],
  );

  const theEntitiyIds = useMemo(
    () => fetchEntitiesData?.map((entity) => entity.id) || [],
    [fetchEntitiesData],
  );

  const handleCopy = useCallback(async () => {
    if (!isSuccessResumeIA) return;
    navigator.clipboard.writeText(textToCopy || '');
    setCopied(true);
    await wait(5000);
    setCopied(false);

    posthog.capture('resume-inteiro-teor', {
      action: 'copy-selected-text',
      card_id: id,
      entityName:
        theOptions?.options.find((option) => option.id === entityId)
          ?.initials || '',
      text: textToCopy,
    });

    PostHogSendResultPage('copy_part_resume_ia', pageView); // POSTHOG_NEW

    setTextToCopy('');
  }, [isSuccessResumeIA, textToCopy, theOptions]);

   const handleUrl = () => {
      window.open(url, '_blank');
    };
  
  const handleFullCopy = useCallback(async () => {
    if (!isSuccessResumeIA) return;
    navigator.clipboard.writeText(theText);

    PostHogSendResultPage('copy_resume_ia', pageView); // POSTHOG_NEW
    setFullCopied(true);
    await wait(5000);
    setFullCopied(false);

    posthog.capture('resume-inteiro-teor', {
      action: 'copy-full-text',
      card_id: id,
      entityName:
        theOptions?.options.find((option) => option.id === entityId)
          ?.initials || '',
      text: theText,
    });
  }, [isSuccessResumeIA, theText, theOptions]);

  const handleFeedback = useCallback(
    (action: 'like' | 'unlike') => {
      if (action === like) return;
      mutateSummaryFeedback({
        card_id: id,
        entity: entityId,
        user_id: userId,
        action,
      });
      setLike(action);
      PostHogSendResultPage('feedback_resume_ia', pageView, action); // POSTHOG_NEW

      posthog.capture('resume-inteiro-teor', {
        action: 'feedback',
        card_id: id,
        entityName:
          theOptions?.options.find((option) => option.id === entityId)
            ?.initials || '',
        feedback: action,
      });
    },
    [theOptions],
  );

  const tooltipText = useMemo(() => {
    switch (true) {
      case !summarizable:
        return 'Ainda estamos implementando o resumo para esse órgão';
      case summarizable && !theEntitiyIds.includes(entityId):
        return 'Ops! Não conseguimos resumir essa decisão';
      default:
        return 'Nossa IA faz o resumo da decisão';
    }
  }, [summarizable, entityId, fetchEntitiesData]);

  // useEffect(() => {
  //   if (open)
  //     return mutateSumary({ card_id: id, entity: entityId, user_id: userId });
  //   setCopied(false);
  //   setTextToCopy('');
  //   setFullCopied(false);
  // }, [open]);

  // useEffect(() => {
  //   if (!fetchSummaryData?.feedback) return setLike(null);
  //   setLike(fetchSummaryData.feedback);
  // }, [fetchSummaryData]);

  useEffect(() => {
    setCopied(false);
  }, [textToCopy]);

  useEffect(() => {
    posthog.people.set({
      email: profile.email,
      company: profile.company.id,
      company_name: profile.company.name,
      name: profile.name,
      is_trial: !!profile.company.asaas_url?.match('^https://www.asaas.com/.*'),
    });
  }, []);

  const stopPropagationOnClick = (event: React.MouseEvent<HTMLElement>) => {
    //evitar que o click do botão acione o bulk action
    event?.stopPropagation();
  };

  // useEffect(() => {
  //   if (!open) return;

  //   posthog.capture('resume-inteiro-teor', {
  //     action: 'opened',
  //     card_id: id,
  //     entityName:
  //       theOptions?.options.find((option) => option.id === entityId)
  //         ?.initials || '',
  //   });
  // }, [open, theOptions]);

  const disabledStyle = { fontWeight: 100 };
  // verifica se o botão de resumir ficará desligado
  const summaryDisabled = !!summarizable || !theEntitiyIds.includes(entityId);

  const OpenAndPostHogEvent = () => {
    setOpen(!open);
    if (!open) {
      PostHogSendResultPage('resume_with_ia', pageView); // POSTHOG_NEW
    }
  };

  const handleFetchResumeIA = () => {
    mutateResumeIA(
      {
        user_id: userId,
        card_ids: [
          {
            card_id: id,
            entity: entityId,
            text: content[0].content,
            extras: {
              content: content,
              dataJul: dataJul,
              dataPub: dataPub,
              data_jul: data_jul,
              data_pub: data_pub,
              data_publicacao_date: data_publicacao_date,
              relator: relator,
              url: url,
            },
            kind: 'ementa',
          },
        ],
      },
      {
        onSuccess: (res) => {
          setDataResumeIA(res);
        },
        onError: (err) => {
          console.error('Erro ao buscar resumo:', err);
        },
      },
    );
  };

  return (
    <Dialog modal={false} open={open} onOpenChange={OpenAndPostHogEvent}>
      <DialogTrigger asChild onClick={stopPropagationOnClick}>
        <a
          className="hover:no-underline"
          style={!summaryDisabled ? {} : disabledStyle}
        >
          <Button
            appearance="blue900"
            size="sm"
            iconPosition="left"
            icon={
              <img
                src={resumeInteiroTeorIcon}
                style={{ filter: summaryDisabled ? 'none' : 'grayscale(100%)' }}
              />
            }
            tooltipText={tooltipText}
            disabled={!summaryDisabled}
            onClick={() => handleFetchResumeIA()}
          >
            <span style={{ color: '#fff' }}>
              {isInMonitoring ? '' : 'Análise com IA'}
            </span>
          </Button>
        </a>
      </DialogTrigger>

      <DialogContent
        onClick={stopPropagationOnClick}
        className="DialogContentClass max-w-2xl"
      >
        <DialogHeader
        >
          <DialogTitle className="gap-1 align-bottom mt-1">
            <span style={{ display: 'flex', gap: '4px' }}>
              <span className="text-start text-base font-poppins font-semibold">
                Resumo do Inteiro Teor
              </span>{' '}
              <span style={{ marginTop: '-2px', marginLeft: '2px' }}>
                <Whisper
                  trigger="hover"
                  placement="top"
                  speaker={
                    <Tooltip>
                      Estamos em desenvolvimento e aprimoramento da nossa IA
                    </Tooltip>
                  }
                >
                  <div className="beta-title-resume-decision">Novo</div>
                </Whisper>
              </span>
            </span>
            <DialogDescription className="desc-resume-decision">
              <div className="title-resumo-ia" onClick={handleUrl}>
                <span>{name}</span>
                <Link1 size={16} color="#0069F5" />
              </div>
            </DialogDescription>
          </DialogTitle>
          <DialogDescription className="scroll-resume-ia text-[#475467] max-h-98 pr-4 overflow-y-auto selection:bg-[#FDFF75] whitespace-pre-line">
            {isPendingResumeIA && (
              <div style={{ marginTop: '14px' }}>
                <div className="gap-1.5 flex items-center mb-7">
                  <div className="w-4 h-4 bg-[#053AC8] rounded-full animate-bounce-zoom" />
                  <div className="w-[406px] h-4 bg-[#D0D5DD] animate-pulse" />
                </div>
                <div className="gap-3 flex flex-col">
                  {[...Array(6)].map((_, index) => (
                    <div
                      key={index}
                      className="w-full h-4 bg-[#F2F4F7] animate-pulse"
                    />
                  ))}
                </div>
              </div>
            )}
            {isSuccessResumeIA && (
              <>
                <ResumeIADecision markdownText={dataResumeIA} />
                <FloatingUI getTextSelected={setTextToCopy}>
                  <>
                    <button
                      onClick={handleCopy}
                      disabled={copied}
                      className="gap-2 flex items-center px-3 py-2 rounded border border-[#344054]  bg-[#101828] hover:opacity-100"
                      style={{ color: 'white' }}
                    >
                      {copied ? (
                        <>
                          <TickCircle size={12} color="white" />
                          Copiado
                        </>
                      ) : (
                        <>
                          <Copy size={12} color="white" />
                          Copiar
                        </>
                      )}
                    </button>
                    <div className="left-1/2 absolute bottom-0 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-4 h-4 bg-[#101828] border-r border-b border-solid border-[#344054]" />
                  </>
                </FloatingUI>
              </>
            )}
            {isErrorResumeIA && <span>Não disponível.</span>}
          </DialogDescription>
        </DialogHeader>

        <hr className="my-2" />
        <div className="gap-[10px] flex justify-between">
          <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
            <Whisper
              trigger="hover"
              placement="top"
              speaker={
                <Tooltip>
                  {fullCopied ? 'Copiado' : 'Copiar todo o resumo'}
                </Tooltip>
              }
            >
              <button
                className={cn(
                  'max-w-fit h-9 py-2 px-4 border border-[#E4E7EC] rounded gap-2 flex items-center justify-center text-xs font-medium leading-5',
                  fullCopied
                    ? 'text-[#12B76A] bg-[#ffffff]'
                    : 'text-[#FFFFFF] bg-[#010C28]',
                )}
                onClick={handleFullCopy}
                disabled={fullCopied || !isSuccessResumeIA}
              >
                {fullCopied ? (
                  <>
                    <TickCircle size={16} color="#12B76A" />
                    Copiado
                  </>
                ) : (
                  <>
                    <img src={documentNormalIcon} width={16} height={16} />
                    Copiar resumo
                  </>
                )}
              </button>
            </Whisper>
            <SaveCardToCollectionButton
              cards={cardForResumo}
              query={query}
              theCollections={theAllCollectionsInOne}
              isItInACollection={isItInACollection}
              icon={
                isItInACollection ? (
                  <div style={{ padding: 0 }}>
                    <ArchiveTick color="#32D583" size="19" variant="Bold" />
                  </div>
                ) : (
                  <div style={{ padding: 0 }}>
                    <ArchiveAdd color="#667085" size="19" />
                  </div>
                )
              }
              appearance="secondary"
              pageView={pageView}
              className={
                'max-w-fit h-9 py-2 px-4 border border-[#E4E7EC] rounded gap-2 flex items-center justify-center text-xs font-medium leading-5 text-[#475467]'
              }
              currentPageBulkAction={''}
            >
              Salvar na coleção
            </SaveCardToCollectionButton>
          </div>
          <div className="gap-1.5 flex items-center">
            <Whisper
              trigger="hover"
              placement="top"
              speaker={<Tooltip>Gostei</Tooltip>}
            >
              <button
                className="p-2 rounded border border-[#E4E7EC] flex justify-center items-center"
                onClick={() => handleFeedback('like')}
                disabled={!(isSuccessResumeIA && isSuccessResumeIA)}
              >
                <Like1
                  size={20}
                  color={
                    like === 'like' && isSuccessResumeIA ? '#12B76A' : '#667085'
                  }
                />
              </button>
            </Whisper>
            <Whisper
              trigger="hover"
              placement="top"
              speaker={<Tooltip>Não gostei</Tooltip>}
            >
              <button
                className="p-2 rounded border border-[#E4E7EC] flex justify-center items-center"
                onClick={() => handleFeedback('unlike')}
                disabled={!(isSuccessResumeIA)}
              >
                <Dislike
                  size={20}
                  color={
                    like === 'unlike' && isSuccessResumeIA
                      ? '#FDB022'
                      : '#667085'
                  }
                />
              </button>
            </Whisper>
          </div>
        </div>
        <span className="font-poppins text-[10px] font-normal leading-4 text-[#98A2B3]">
          Toda IA pode cometer erros. Cheque informações importantes
        </span>
      </DialogContent>
      {open && <div className="backgroundIAOpen" />}
    </Dialog>
  );
};
