import { Button } from '../../../../../ds-components';
import resume_inteiro_teor_bulkaction from '../../../../../assets/svgs/resume-inteiro-teor-bulkaction.svg';
import { useState } from 'react';
import { Dialog, DialogTrigger } from '../../../../../components/ui/dialog';
import { ResumeIABulkActionContainer } from '../../../../../components/ResumeInteiroTeorBulkAction/ResumeIABulkActionContainer/ResumeIABulkActionContainer';
import { useSelector } from 'react-redux';
import { PostHogSendBulkAction } from '../../../../../utils/PostHogSendEvents';

import './ResumeInteiroTeorBulkAction.css';

export const ResumeInteiroTeorBulkAction = ({
  currentPageBulkAction,
  isItInACollection,
}) => {
  const [open, setOpen] = useState(false);
  const theCards = useSelector(
    (state) => state.collectionsBulkaction.selectedCards,
  );
  return (
    <Dialog>
      <DialogTrigger
        asChild
        onClick={() => {
          setOpen(true);
          PostHogSendBulkAction('resume_ia_bulkaction', currentPageBulkAction);
        }}
      >
        <Button
          appearance="primary"
          iconPosition="left"
          className="button-resume-decision"
          icon={<img src={resume_inteiro_teor_bulkaction} />}
        >
          <p className="text-button-resume-decision">
            Resumir decisões
            <span className="text-count-cards-selected-resume">
              {theCards.length}
            </span>
          </p>
        </Button>
      </DialogTrigger>
      {open && (
        <>
          <ResumeIABulkActionContainer
            isItInACollection={isItInACollection}
            pageView={''}
            setOpen={setOpen}
            open={open}
          />
          <div
            className="backgroundIAOpenBulkAction"
            onClick={() => setOpen(false)}
          />
        </>
      )}
    </Dialog>
  );
};
