import { Chart1, SearchZoomIn } from 'iconsax-react';
import React from 'react';
import './Nav.scss'
import { Tooltip, Whisper } from 'rsuite';

export type NavProps = {
  navItems: [{ id: number; title: string; 'data-testid'?: string }];
  activeTab: number;
  handleChange: (activeTab: number) => void;
  resultPage?: boolean
};

export const Nav: React.FC<NavProps> = ({
  navItems,
  activeTab,
  handleChange,
  resultPage
}) => {
  return (
    <div className="tv-nav">
      <ul className="tv-nav_tabs">
        {navItems.map((item, index) => {
          const isActive = activeTab === item.id; 
          const textPesquisa = 'Veja a jurimetria desse tema'
          const textJurimetria ='Veja as decisões referentes a esse tema';
          return (
            <Whisper
            speaker={<Tooltip>{index === 0 ?textJurimetria:textPesquisa}</Tooltip>}
            >
            <li
              key={item.id}
              className={`tv-nav_tabs-items${
                isActive ? ' tv-nav_tabs-items-active' : ''
              } ${resultPage ? ' resultspagesize' : ''}`}
              {...(item['data-testid'] && {
                'data-testid': item['data-testid'],
              })}
            >
              <a onClick={() => handleChange(item.id)} className="box-item">
            
                {index === 0 && (
                  <SearchZoomIn
                    size="18"
                    color={isActive ? "#101828" : "#667085"} 
                    
                  />
                )}
                {index === 1 && (
                  <Chart1
                    size="18"
                    color={isActive ? "#101828" : "#667085"} 
                    
                  />
                )}
                {item.title}
              </a>
            </li>
            </Whisper>
          );
        })}
      </ul>
    </div>
  );
};
