import React, { useEffect, useRef, useState } from 'react';
import * as Icons from 'iconsax-react';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Col,
  Schema,
  CheckboxGroup,
  Form,
  Checkbox,
  MaskedInput,
  TagPicker,
  Grid,
  Whisper,
  Popover,
  Tooltip,
  Loader,
} from 'rsuite';
import { TuriviusValidate } from '../../../utils/ValidateInputs';
import './CaseCard.scss';
import { withRouter } from 'react-router-dom';
import CreditPopover from './CreditPopover';
import { requester } from '../../../requesters';

import { PostHogSendLaborJurimetry } from '../../../utils/PostHogSendEvents';

const mask = [
  /[0-9]/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

const Field = React.forwardRef((props, ref) => {
  const { controlId, name, message, label, accepter, error, ...rest } = props;
  return (
    <Form.Group controlId={controlId}>
      <Form.Control name={name} accepter={accepter} {...rest} />
      <Form.HelpText>{message}</Form.HelpText>
    </Form.Group>
  );
});

const getMenuStatusFromHistory = (key) => {
  const value = localStorage.getItem(key);

  // Se for booleano, retorna diretamente
  if (typeof value === 'boolean') {
    return value;
  }

  // Se for string 'true' ou 'false', converte para booleano
  if (value === 'true') {
    return true;
  } else if (value === 'false') {
    return false;
  }

  // Se não existir (null ou outro valor inválido), retorna false
  return false;
};

const CaseCard = ({
  info,
  paramsSearch,
  entities,
  topics,
  permissions,
  cardPermission,
  ...props
}) => {
  const localMenuStatus = getMenuStatusFromHistory('menu_open');
  const formRef = useRef();
  const [modal, setModal] = useState(false);
  const [modelForm, setModelForm] = useState();
  const [step, setStep] = useState(0);
  const [formQuestions, setFormQuestions] = useState({});
  const [formError, setFormError] = useState({});
  const [menuStatus, setMenuStatus] = useState(localMenuStatus);
  const IconComponent = Icons[info.icon];

  const handleModal = () => {
    setModal(!modal);
  };

  const onSearch = () => {
    if (!formRef.current.check()) {
      console.error('Form Error');
    } else {
      const questions = {};

      Object.keys(formQuestions).forEach((lbl) => {
        if (lbl === 'cnpj') {
          questions[lbl] = formQuestions[lbl]
            .replaceAll('.', '')
            .replace('/', '')
            .replace('-', '');
        } else {
          questions[lbl] = formQuestions[lbl];
        }
      });
      props.history.push(
        `/jurimetria-trabalhista/busca?case=${info.id}&filters=${JSON.stringify(
          questions,
        )}`,
      );
      if (info.id == 'cnpj') {
        PostHogSendLaborJurimetry('cnpj', formQuestions.cnpj); // POSTHOG_NEW
      } else if (info.id == 'assunto') {
        PostHogSendLaborJurimetry('assunto'); // POSTHOG_NEW
      } else if (info.id == 'estado') {
        PostHogSendLaborJurimetry('estado'); // POSTHOG_NEW
      }
    }
  };

  const generateDataModel = () => {
    let model = {};

    info.questions.forEach((q) => {
      switch (q.type) {
        case 'cnpj-input':
          model[`${q.field}`] = Schema.Types.StringType().addRule(
            (value, data) => {
              return (
                !value.includes('_') &&
                TuriviusValidate.validateCNPJ(value, 'withMask')
              );
            },
            'Informe um número de CNPJ válido',
          );
          break;
        case 'toggle-button':
          model[`${q.field}`] = Schema.Types.ArrayType().minLength(
            1,
            'Selecione pelo menos 1 estado (TRT).',
          );
          break;
        case 'tag-input':
          model[`${q.field}`] = Schema.Types.ArrayType()
            .minLength(1, 'Selecione pelo menos 1 tema.')
            .maxLength(5, 'A quantidade máxima de temas foi atingido.');
          break;

        default:
          break;
      }

      if (q.required) {
        model[`${q.field}`].isRequired('Este campo é obrigatório');
      }
    });

    setModelForm(Schema.Model(model));
  };

  const getItemForm = (type, question) => {
    switch (type) {
      case 'cnpj-input':
        return (
          <Field
            controlId={question.type}
            name={question.field}
            placeholder="Ex.: 00.000.000/0000-00"
            mask={mask}
            accepter={MaskedInput}
          />
        );
      case 'tag-input':
        return (
          <Field
            controlId={question.type}
            name={question.field}
            accepter={TagPicker}
            size="lg"
            placeholder="Selecione até 5 temas"
            style={{ display: 'inline-block', width: '50vh', marginTop: 20 }}
            data={topics?.map((item) => ({
              label: item,
              value: item,
            }))}
            renderMenu={(menu) => {
              if (topics.length === 0) {
                return (
                  <p
                    style={{
                      display: 'flex',
                      gap: 5,
                      padding: 5,
                      justifyContent: 'center',
                    }}
                  >
                    <Loader />
                    Carregando Assuntos...
                  </p>
                );
              }
              return menu;
            }}
          />
        );
      case 'toggle-button':
        return (
          <Field
            controlId={question.type}
            name={question.field}
            accepter={CheckboxGroup}
            style={{ width: '80vh', margin: '20px 0' }}
          >
            {entities.length > 0 ? (
              <Grid fluid>
                {entities?.map((e) => (
                  <div key={e}>
                    <Whisper
                      trigger="hover"
                      placement="bottom"
                      speaker={<Tooltip>{e}</Tooltip>}
                    >
                      <Col
                        xs={24}
                        sm={10}
                        md={24 / 4}
                        lg={24 / 4}
                        xl={24 / 4}
                        xxl={24 / 4}
                      >
                        <Checkbox value={e}>{e}</Checkbox>
                      </Col>
                    </Whisper>
                  </div>
                ))}
                <Col
                  xs={24}
                  sm={10}
                  md={24 / 4}
                  lg={24 / 4}
                  xl={24 / 4}
                  xxl={24 / 4}
                >
                  <Whisper
                    placement="bottom"
                    trigger="click"
                    speaker={
                      <Popover id="state-soon-popover">
                        <h6>Em Breve</h6>
                        <p>
                          Estamos aperfeiçoando nossa base de dados sobre os
                          demais Estados.
                        </p>
                      </Popover>
                    }
                  >
                    <div className="state-soon">
                      <h6>Mais Estados</h6>
                    </div>
                  </Whisper>
                </Col>
              </Grid>
            ) : (
              <Loader size="md" content="Carregando Tribunais..." />
            )}
          </Field>
        );

      default:
        return <Field name={question.field} placeholder="Responda aqui..." />;
    }
  };

  useEffect(() => {
    generateDataModel();
  }, []);

  useEffect(() => {
    window.addEventListener('menuOpen', () => {
      setMenuStatus(JSON.parse(localStorage.getItem('menu_open')));
    });
  }, []);

  return (
    <div className="case-card-content">
      <Col xs={24} sm={24} md={24} lg={24}>
        <div className="card-case card-case-border" onClick={handleModal}>
          <div className="card-case-title">
            <div className="icon-box">
              {IconComponent ? (
                <IconComponent size={48} color="#98A2B3" />
              ) : null}
            </div>
            {/* Ícone PRO antes do título */}
            <h6>
              {info.title}
              {cardPermission?.has_permission == 0 && (
                <Whisper
                  trigger={'hover'}
                  placement="top"
                  speaker={
                    <Tooltip>
                      {
                        'Entre em contato com nosso time de vendas através do nosso whatsapp'
                      }
                    </Tooltip>
                  }
                >
                  <span
                    style={{
                      backgroundColor: '#1E1E1E',
                      color: '#FFF',
                      fontSize: '10px',
                      fontWeight: 'bold',
                      borderRadius: '10px',
                      padding: '2px 6px',
                      marginLeft: '8px',
                      marginBottom: '5px',
                      verticalAlign: 'middle',
                      display: 'inline-block',
                    }}
                  >
                    PRO
                  </span>
                </Whisper>
              )}
            </h6>
          </div>

          <div className={`card-case-description`}>
            <p dangerouslySetInnerHTML={{ __html: info.description }} />
          </div>

          <div className="card-case-action">
            <Button className="btn-card-case-action" onClick={handleModal}>
              {info.title}
            </Button>
            {!cardPermission?.has_permission == 0 && (
              <CreditPopover
                usedCredit={cardPermission?.event_count}
                totalCredit={cardPermission?.has_permission}
              ></CreditPopover>
            )}
          </div>
        </div>
      </Col>

      <div
        className={`turivius-modal ${modal ? 'visibility' : 'hidden'} ${
          menuStatus ? '' : 'menu-close'
        }`}
      >
        <div
          className={`turivius-modal-header ${menuStatus ? '' : 'menu-close'}`}
        >
          <Button
            className="btn-close-action"
            appearance="link"
            onClick={handleModal}
          >
            FECHAR
          </Button>
        </div>
        <div className="turivius-modal-body">
          <h2>
            {info.questions[step].question}{' '}
            {info.questions[step].required ? '*' : null}
          </h2>
          <span>{info.questions[step].description}</span>
          <Form
            fluid
            model={modelForm}
            ref={formRef}
            onChange={(value) => setFormQuestions(value)}
            onCheck={setFormError}
            formError={formError}
            formValue={formQuestions}
            style={{ width: '100%', margin: 0 }}
          >
            {info.questions?.map((q) =>
              q.questionId === step + 1 ? getItemForm(q.type, q) : null,
            )}
          </Form>
          <Button onClick={onSearch} className="btn-search-action">
            Buscar
            <i
              className="ri-arrow-right-line"
              style={{ color: '#84d4ff', fontSize: 25 }}
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CaseCard);
