import { posthog } from '../../../../../requesters';
import './InteiroTeorFilter.css';

const InteiroTeorFilter = (props) => {
  const handleChange = (newOption) => {
    const { context, setInteiroTeorFilter } = props;

    if (context) {
      posthog.capture(context, {
        action: 'change_parameter',
        id: 'ementa_inteiro_teor',
      });
    }

    setInteiroTeorFilter(JSON.parse(newOption));
  };

  const selected = JSON.stringify(props.inteiroTeorFilter);

  return (
    <div className="inteiro-teor-filter">
      <span className="title-option">Buscar em</span>

      <div className="inteiro-teor-button-group">
        {[
          { label: 'Todos', value: '["ementa","full_text"]' },
          { label: 'Ementa', value: '["ementa"]' },
          { label: 'Inteiro Teor', value: '["full_text"]' },
        ].map((option) => (
          <button
            key={option.value}
            type="button"
            className={`inteiro-teor-button ${
              selected === option.value ? 'active' : ''
            }`}
            onClick={() => handleChange(option.value)}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default InteiroTeorFilter;
