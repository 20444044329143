import React, { useState, useEffect, useCallback, memo } from 'react';
import { Button as ButtonTuring } from '../../../../../ds-components';
import { requester } from '../../../../../requesters';
import InteiroTeorFilter from './Filters/InteiroTeorFilter';
import FilterWrapper from './FilterWrapper';
import { FiltersSkeleton } from '../../../../../components/Skeleton';
import NewDateFilter from './Filters/NewFilterList/components/NewDateFilter/NewDateFilter';
import TaxFilters from './Filters/NewTaxFilters/TaxFilters';
import { NewFilterList } from './Filters/NewFilterList/NewFilterList';
import { getResultById } from './Filters/NewFilterList/FilterPath';
import { useLocation } from 'react-router-dom';
import './filterList.scss';

import { PostHogSendFilter } from '../../../../../utils/PostHogSendEvents';
import { RecursiveFilter } from './Filters/RecursiveFilter/RecursiveFilter';

// FIXME: Otimização para o componente não rendeizar se as props não mudarem
const FiltersList = memo((props) => {
  const [trigger, setTrigger] = useState(false);
  const [filters, setFilters] = useState(null);
  const [requesting, setRequesting] = useState(true);
  const [selectedDecisionOptions, setSelectedDecisionOptions] = useState([]);
  const [selectedTypeOptions, setSelectedTypeOptions] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [appliedFilterId, setAppliedFilterId] = useState(null);
  const [clear, setClear] = useState(false);
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const [refreshRelator, setRefreshRelator] = useState(false);
  const [resultById, setResultById] = useState(null);
  const [synonymsConfirm, setSynonymsConfirm] = useState(true);
  const [dataOption, setDataOption] = useState('');
  const location = useLocation();

  useEffect(() => {
    setRefreshRelator(!refreshRelator);
  }, [props.isSearching]);

  useEffect(() => {
    let storedFields = localStorage.getItem('fields');
    if (!storedFields) {
      storedFields = JSON.stringify(['ementa', 'full_text']);
      localStorage.setItem('fields', storedFields);
    }
    getFilters();
    getResultByIdAndHandle();
  }, []);

  useEffect(() => {
    if (appliedFilterId !== null) {
      getResultByIdAndHandle();
    }
  }, [appliedFilterId]);

  const getFilters = useCallback(async () => {
    if (filters || props.filters) return; // Evita chamadas desnecessárias

    setRequesting(true);
    try {
      const response = await requester.cards.getAllFilters();
      const defaultFilters = response.reduce((acc, filter) => {
        if (filter.field_name === 'entity') {
          acc.entities = filter.default;
        } else {
          acc[filter.field_name] = filter.default;
        }
        return acc;
      }, {});

      props.filtersProps?.setOriginalFilters(response);
      props.filtersProps?.setDefaultFilters(defaultFilters);
      props.filtersProps?.loadFilters();
      setFilters(response);
    } catch (error) {
      console.error(error);
    } finally {
      setRequesting(false);
    }
  }, [filters, props.filters]);

  const getResultByIdAndHandle = async () => {
    if (!appliedFilterId) return;

    try {
      const result = await getResultById(appliedFilterId);
      setResultById(result);
    } catch (error) {
      console.error('Erro ao obter o resultado por ID:', error);
    }
  };

  const clearSelectedFilters = () => {
    setSelectedDecisionOptions([]);
    setSelectedTypeOptions([]);
    setSelectedFilters({});
    setAppliedFilterId(null);
  };

  // FIXME: Evitar renderizações desnecessárias
  // O useCallback é para performance(dos componentes abaixo não renderizarem)
  const updateSelectedFilters = useCallback((updatedValues) => {
    setSelectedFilters((prevFilters) => {
      const hasChanged = Object.keys(updatedValues).some(
        (key) => prevFilters[key] !== updatedValues[key],
      );

      if (!hasChanged) return prevFilters;

      return { ...prevFilters, ...updatedValues };
    });
  }, []);

  // const updateSelectedFilters = (updatedValues) => {
  //   setSelectedFilters((prevFilters) => ({
  //     ...prevFilters,
  //     ...updatedValues,
  //   }));
  // };

  const handleDecisionOptionChange = (id) => {
    setSelectedDecisionOptions((prevOptions) =>
      prevOptions.includes(id)
        ? prevOptions.filter((option) => option !== id)
        : [...prevOptions, id],
    );
  };

  const handleTypeOptionChange = (id) => {
    setSelectedTypeOptions((prevOptions) =>
      prevOptions.includes(id)
        ? prevOptions.filter((option) => option !== id)
        : [...prevOptions, id],
    );
  };

  const handleStateUpdate = (newState) => {
    if (newState && newState.dateRange) setDateRange(newState.dateRange);
    if (newState && newState.trigger !== undefined)
      setTrigger(newState.trigger);
    if (newState && newState.clear !== undefined) {
      setClear(newState.clear);
      // props.setClearForChildren(clear);
    }
  };

  const isQueryEmpty = !props.query || props.query.trim() === '';

  const SendPostHogFiilterEvent = async () => {
    try {
      // TODOS os orgaos e Filtro para comparar/usar
      const entities = await requester.cards.getEntities();
      const entitiesIDandInitials = entities.map(
        ({ id, initials, class: entityClass }) => ({
          id,
          initials,
          class: entityClass,
        }),
      );
      const filters = JSON.parse(localStorage.getItem('filters'));
      // --------

      //Gatilho do Buscar Em
      const search_in =
        JSON.parse(localStorage.getItem('fields')).length == 2
          ? 'todos'
          : JSON.parse(localStorage.getItem('fields'))[0];

      //Gatilho das Datas
      const date = {
        date: {
          de: filters.date_range[0],
          ate: filters.date_range[1],
        },
      };

      //Gatilho dos Filtros  - 89 é o numero total de filtro (todos os filtros ativos)
      const entitiesFilted = entitiesIDandInitials.reduce(
        (acc, { id, initials, class: entityClass }) => {
          if (!acc[entityClass]) {
            acc[entityClass] = new Set();
          }
          if (filters.entities.includes(id)) {
            acc[entityClass].add(initials);
          }
          return acc;
        },
        {},
      );
      Object.keys(entitiesFilted).forEach((key) => {
        entitiesFilted[key] = Array.from(entitiesFilted[key]);
      });

      const totalCount = Object.values(entitiesFilted).reduce(
        (acc, array) => acc + array.length,
        0,
      );

      //Gatilhos dos Tributarios
      const classification = await requester.cards.getAllFilters();
      const classificationFilters = classification
        .filter(({ field_name }) =>
          [
            'filter_by_favorability',
            'filter_by_secao_carf',
            'filter_by_vote',
          ].includes(field_name),
        )
        .map(({ field_name, applied_name, options }) => ({
          field_name,
          applied_name,
          options,
        }));

      const classificationFilted = {
        filterfavorability: filters.filter_by_favorability,
        secaocarf: filters.filter_by_secao_carf,
        vote: filters.filter_by_vote,
      };

      const classificationMappedFilters = {
        filter_by_favorability: classificationFilters.find(
          (f) => f.field_name === 'filter_by_favorability',
        )
          ? classificationFilters
              .find((f) => f.field_name === 'filter_by_favorability')
              .options.filter((option) =>
                classificationFilted.filterfavorability.includes(option.id),
              )
              .map((option) => option.name)
          : [],

        filter_by_secao_carf: classificationFilters.find(
          (f) => f.field_name === 'filter_by_secao_carf',
        )
          ? classificationFilters
              .find((f) => f.field_name === 'filter_by_secao_carf')
              .options.filter(
                (option) =>
                  classificationFilted.secaocarf &&
                  classificationFilted.secaocarf.includes(option.id),
              )
              .map((option) => option.name)
          : [],

        filter_by_vote: classificationFilters.find(
          (f) => f.field_name === 'filter_by_vote',
        )
          ? classificationFilters
              .find((f) => f.field_name === 'filter_by_vote')
              .options.filter(
                (option) =>
                  classificationFilted.vote &&
                  classificationFilted.vote.includes(option.id),
              )
              .map((option) => option.name)
          : [],
      };

      PostHogSendFilter(
        'apply_filter',
        props.currentPage,
        search_in,
        null,
        null,
        entitiesFilted['Órgãos Administrativos'],
        entitiesFilted['Tribunais Superiores'],
        entitiesFilted['Justiça Federal'],
        entitiesFilted['Justiça Estadual'],
        entitiesFilted['Justiça do Trabalho'],
        null,
        null,
        null,
        null,
      ); // POSTHOG_NEW - Alguns estao null pk o Isra pediu pra tirar os que nao vao usar no grafico
    } catch (error) {
      console.error('Erro ao buscar entidades:', error);
    }
  };

  useEffect(() => {
    if (props.setFiltersDataRecursive) props.setFiltersDataRecursive(filters);
  }, [filters]);

  return (
    <div
      className="tv-filters_list w-[400px] md:w-[90vw]"
      style={{
        height: location.pathname == '/pesquisa' ? '86vh' : '',
        transition: 'width 0.6s ease',
        paddingRight: location.pathname == '/pesquisa' ? '20px' : '',
        paddingLeft: location.pathname == '/pesquisa' ? '4px' : '',

        marginTop:
          window.location.pathname == '/pesquisa'
            ? window.innerHeight < 820
              ? '70px'
              : '66px'
            : props.selectedCards?.length > 0
              ? '185px'
              : '132px',
        transition: 'margin-top 0.2s ease-in-out',
      }}
    >
      {requesting ? (
        <FiltersSkeleton />
      ) : (
        <>
          <div className="tv-filters_body">
            <NewFilterList
              selectedFilters={selectedFilters}
              updateSelectedFilters={updateSelectedFilters}
              filtersProps={props.filtersProps}
              setValueStyles={props.setValueStyles}
              search={props.search}
              setNewValue={props.setNewValue}
              setValitadionFilter={props.setValitadionFilter}
              handleStateUpdate={handleStateUpdate}
              setClearFiltro={props.setClearFiltro}
              trigger={trigger}
              setTrigger={() => setTrigger(!trigger)}
              setRefreshRelator={() => setRefreshRelator(!refreshRelator)}
              colapse={props.colapse}
              setColapse={props.setColapse}
              setClear={setClear}
              setClearForChildren={props.setClearForChildren}
              clear={clear}
              query={props.query}
              filtersPropsToNewFilter={filters}
            />
            <InteiroTeorFilter
              context="Results"
              filtersProps={props.filtersProps}
              updateSelectedFilters={updateSelectedFilters}
              resultById={resultById?.where_search}
              setClear={setClear}
              setClearForChildren={props.setClearForChildren}
              clear={clear}
              AllProps={props?.AllProps}
              setSynonymsConfirm={setSynonymsConfirm}
            />
            {/* <RecursiveFilter
              filtersProps={props.filtersProps}
              setRecursiveChildrenOpen={props.setRecursiveChildrenOpen}
              handleMouseMove={props.handleMouseMove}
              setIsMouseEntered={props.setIsMouseEntered}
              searchTerm={props.searchTerm}
              setSearchTerm={props.setSearchTerm}
              filters={filters}
              setSelectedRecurse={props.setSelectedRecurse}
              groupData={props.groupData}
              isCheckedsRecursives={props.isCheckedsRecursives}
              setIsCheckedsRecursives={props.setIsCheckedsRecursives}
              clear={clear}
            /> */}
            <NewDateFilter
              filters={filters}
              filtersProps={props.filtersProps}
              tmpFilters={props.tmpFilters}
              entities={props.entities}
              additionalProp1={props.additionalProp1}
              additionalProp2={props.additionalProp2}
              selectedFilters={selectedFilters}
              updateSelectedFilters={updateSelectedFilters}
              endDate={resultById?.end_date}
              startDate={resultById?.start_date}
              dateRange={dateRange}
              setDateRange={setDateRange}
              trigger={trigger}
              setClear={setClear}
              setClearForChildren={props.setClearForChildren}
              clear={clear}
              setDataOption={setDataOption}
            />
            <form className="tv-filters_content">
              {filters
                ? filters.map((filter, index) => (
                    <FilterWrapper
                      key={index}
                      filters={filters}
                      context="Results"
                      filter={filter}
                      tmpFilters={props.tmpFilters}
                      entities={props.entities}
                      filtersProps={props.filtersProps}
                      selectedFilters={selectedFilters}
                      updateSelectedFilters={updateSelectedFilters}
                      resultById={resultById}
                      setClear={setClear}
                      setClearForChildren={props.setClearForChildren}
                      clear={clear}
                      isHome={props.isHome}
                      refreshRelator={refreshRelator}
                      requesting={props.requesting}
                      isSearching={props.isSearching}
                    />
                  ))
                : null}
            </form>
          </div>
          <TaxFilters
            filters={filters}
            AllProps={props?.AllProps}
            onDecisionOptionChange={handleDecisionOptionChange}
            onTypeOptionChange={handleTypeOptionChange}
            selectedFilters={selectedFilters}
            updateSelectedFilters={updateSelectedFilters}
            resultById={resultById}
            clear={clear}
            isHome={props.isHome}
          />
        </>
      )}
      {
        <div className="tv-filters_footer">
          <ButtonTuring
            size="md"
            fullWidth
            appearance="blue900"
            onClick={() => {
              props.filtersProps.saveFilters(
                props.filtersProps.history,
                props.filtersProps.match,
                encodeURIComponent(props.query),
              );
              setRefreshRelator(!refreshRelator);
              SendPostHogFiilterEvent();
            }}
            disabled={isQueryEmpty}
            className="ButtonTuring"
            icon={<img src="/icon-filter.png" className="icon-filter" />}
            iconPosition="left"
          >
            Aplicar filtro{' '}
            {!!props?.AllProps?.countResults
              ? `(${props.AllProps.countResults} resultados)`
              : ''}
          </ButtonTuring>
        </div>
      }
    </div>
  );
});

export default FiltersList;
