import React, { useState, useEffect, useRef } from 'react';
import { Input, Panel } from 'rsuite';
import { SearchNormal1, VideoPlay, ArrowUp2, ArrowDown2 } from 'iconsax-react';
import { getAll, getBySession } from '../../services/academy';

const PanelHeader = ({ collapsible, setCollapsible, item, hasVideo }) => {
  return (
    <div
      className="panel-header-bottom item"
      onClick={() => {
        setCollapsible(!collapsible);
      }}
    >
      <span style={{ display: 'flex', gap: '6px' }}>
        {hasVideo ? <VideoPlay size="20" color="#839be4" /> : null}
        <label>{item}</label>
      </span>
      {collapsible ? (
        <ArrowUp2 color="#FFFFFF" size={16} />
      ) : (
        <ArrowDown2 color="#FFFFFF" size={16} />
      )}
    </div>
  );
};

const HelperCenter = ({ session }) => {
  const [filter, setFilter] = useState(null);
  const [filterInput, setFilterInput] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [collapsibles, setCollapsibles] = useState({});
  const contentRefs = useRef({});

  async function getQuestions() {
    const academy = await getBySession(session);
    const allAcademy = await getAll();

    setAllQuestions(allAcademy.filter((a) => a.actived === true));
    setFilter(academy.filter((a) => a.actived === true));
    setQuestions(academy.filter((a) => a.actived === true));
  }

  function getURL(uur) {
    const url = new URLSearchParams(uur.substring(29, uur.length));
    const urlT = url.get('v');
    return `https://www.youtube.com/embed/` + urlT;
  }

  function onFilter(value) {
    setFilterInput(value);
    setFilter(
      allQuestions.filter(
        (i) =>
          i.query.toLowerCase().includes(value.toLowerCase()) ||
          i.answer.toLowerCase().includes(value.toLowerCase()),
      ),
    );

    if (value === '') {
      setFilter(questions);
    }
  }

  function toggleCollapsible(id) {
    setCollapsibles((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  }

  useEffect(() => {
    getQuestions();
  }, []);

  return questions.length > 0 ? (
    <div className="help-center-container">
      <label className="title-help-center">Precisa de ajuda?</label>
      <div className="help-center-input-group">
        <div className="help-center-input-group_icon">
          <SearchNormal1 size={15} color="#98A2B3" />
        </div>
        <Input
          className="help-center-input-group_input"
          placeholder="Digite o que quer saber"
          onChange={(value) => onFilter(value)}
          value={filterInput}
        />
      </div>
      {filter?.map((info) => (
        <Panel
          id={info?.id}
          className="knowledge-panel"
          header={
            <PanelHeader
              collapsible={collapsibles[info?.id]}
              item={info?.query}
              hasVideo={info?.videoURL}
              setCollapsible={() => toggleCollapsible(info?.id)}
            />
          }
        >
          <div
            ref={(el) => (contentRefs.current[info?.id] = el)}
            className="collapsible-content"
            style={{
              maxHeight: collapsibles[info?.id]
                ? `${contentRefs.current[info?.id]?.scrollHeight}px`
                : '0px',
              transition: 'max-height 0.2s ease-in-out',
            }}
          >
            <div
              className="knowledge-panel_body item"
              onClick={() => toggleCollapsible(info?.id)}
            >
              {info?.answer ? info.answer : null}

              {info?.videoURL ? (
                <iframe
                  style={{ borderRadius: 28 }}
                  width="481"
                  height="250"
                  src={getURL(info.videoURL)}
                  frameBorder="0"
                  allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
              ) : null}
            </div>
          </div>
        </Panel>
      ))}
    </div>
  ) : (
    <div className="help-center-container_empty">
      <label className="title-help-center">Precisa de ajuda?</label>
      <p>Nenhuma dúvida disponível</p>
    </div>
  );
};

export default HelperCenter;
