import { useState, useEffect } from 'react';
import { ArrowDown2, ArrowRight2, ArrowUp2 } from 'iconsax-react';
import './TaxFilters.scss';

const TaxFilters = ({
  filters,
  onDecisionOptionChange,
  onTypeOptionChange,
  updateSelectedFilters,
  resultById,
  AllProps,
  clear,
}) => {
  const [isDecisionOpen, setIsDecisionOpen] = useState(false);
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [toggleSessionOpen, setToggleSessionOpen] = useState(false);

  const [selectedDecisionOptions, setSelectedDecisionOptions] = useState([
    1, 2, 3, 4,
  ]);
  const [selectedTypeOptions, setSelectedTypeOptions] = useState([]);
  const [selectedSessionOptions, setSelectedSessionOptions] = useState([]);

  const toggleDecision = () => setIsDecisionOpen(!isDecisionOpen);
  const toggleType = () => setIsTypeOpen(!isTypeOpen);
  const SessionOpen = () => setToggleSessionOpen(!toggleSessionOpen);
  const filterFavorability = (filters && filters[3]) || {};
  const filterVote = (filters && filters[4]) || {};
  const filterCarf = (filters && filters[6]) || {};

  useEffect(() => {
    const originalSetItem = localStorage.setItem;
    localStorage.setItem = function (key, value) {
      if (key === 'filters') {
        const updatedFilters = JSON.parse(value);
        if (updatedFilters) {
          setSelectedDecisionOptions(
            updatedFilters.filter_by_favorability?.map((item) => item),
          );
          setSelectedTypeOptions(
            updatedFilters.filter_by_vote?.map((item) => item),
          );
          setSelectedSessionOptions(
            updatedFilters.filter_by_secao_carf?.map((item) => item),
          );
        }
      }
      originalSetItem.apply(this, arguments);
    };

    return () => {
      localStorage.setItem = originalSetItem;
    };
  }, []);

  const updateURLParams = (paramName, values) => {
    try {
      const params = new URLSearchParams(window.location.search);
      const filters = JSON.parse(params.get('filters') || '{}');
      filters[paramName] = values;
      params.set('filters', JSON.stringify(filters));

      if (window.location.pathname == '/pesquisa/resultados') {
        window.history.replaceState(
          null,
          '',
          `${window.location.pathname}?${params.toString()}`,
        );
      }
    } catch (error) {
      console.error('Erro ao atualizar os parâmetros da URL:', error);
    }
  };

  const handleCheckboxChange = (id, filterType) => {
    let filtersLocal = localStorage.getItem('filters');
    filtersLocal = JSON.parse(filtersLocal) || {};

    if (filterType === 'decision') {
      const newSelected = selectedDecisionOptions?.includes(id)
        ? selectedDecisionOptions.filter((option) => option !== id)
        : [...selectedDecisionOptions, id];

      setSelectedDecisionOptions(newSelected);
      updateSelectedFilters({ decision: newSelected });
      onDecisionOptionChange?.(id);

      filtersLocal.filter_by_favorability = newSelected;
      localStorage.setItem('filters', JSON.stringify(filtersLocal));
      updateURLParams('filter_by_favorability', newSelected);
    } else if (filterType === 'type') {
      const currentTypeOptions = Array.isArray(selectedTypeOptions)
        ? selectedTypeOptions
        : [];

      const newSelected = currentTypeOptions.includes(id)
        ? currentTypeOptions.filter((option) => option !== id)
        : [...currentTypeOptions, id];

      setSelectedTypeOptions(newSelected);
      updateSelectedFilters({ type: newSelected });
      onTypeOptionChange?.(id);

      filtersLocal.filter_by_vote = newSelected;
      localStorage.setItem('filters', JSON.stringify(filtersLocal));
      updateURLParams('filter_by_vote', newSelected);
    } else if (filterType === 'session') {
      const currentSessionOptions = Array.isArray(selectedSessionOptions)
        ? selectedSessionOptions
        : [];
      const newSelected = currentSessionOptions.includes(id)
        ? currentSessionOptions.filter((option) => option !== id)
        : [...currentSessionOptions, id];

      setSelectedSessionOptions(newSelected);
      updateSelectedFilters({ session: newSelected });

      filtersLocal.filter_by_secao_carf = newSelected;
      localStorage.setItem('filters', JSON.stringify(filtersLocal));
      updateURLParams('filter_by_secao_carf', newSelected);
    }
  };

  useEffect(() => {
    let filtersLocal = localStorage.getItem('filters');
    filtersLocal = JSON.parse(filtersLocal) || {};
    const params = new URLSearchParams(window.location.search);
    const filtersFromUrl = JSON.parse(params.get('filters') || '{}');

    if (filtersFromUrl.filter_by_favorability) {
      setSelectedDecisionOptions(filtersLocal.filter_by_favorability || []);
    }

    if (resultById?.filter_by_favorability) {
      setSelectedDecisionOptions(resultById.filter_by_favorability || []);
    }

    if (filtersFromUrl.filter_by_vote) {
      setSelectedTypeOptions(filtersLocal.filter_by_vote || []);
    }

    if (resultById?.filter_by_vote) {
      setSelectedTypeOptions(resultById.filter_by_vote || []);
    }

    if (filtersFromUrl.filter_by_secao_carf) {
      setSelectedSessionOptions(filtersLocal.filter_by_secao_carf || []);
    }

    if (resultById?.filter_by_secao_carf) {
      setSelectedSessionOptions(resultById.filter_by_secao_carf || []);
    }
  }, [resultById]);

  const handleSelectAllDecision = (isChecked) => {
    const allIds = filterFavorability.options?.map((item) => item.id) || [];
    const newSelected = isChecked ? allIds : [];
    setSelectedDecisionOptions(newSelected);

    let filtersLocal = JSON.parse(localStorage.getItem('filters')) || {};
    filtersLocal.filter_by_favorability = newSelected;
    localStorage.setItem('filters', JSON.stringify(filtersLocal));
    updateURLParams('filter_by_favorability', newSelected);

    updateSelectedFilters({ decision: newSelected });
  };

  const handleSelectAllType = (isChecked) => {
    const allIds = filterVote.options?.map((item) => item.id) || [];
    const newSelected = isChecked ? allIds : [];
    setSelectedTypeOptions(newSelected);

    let filtersLocal = JSON.parse(localStorage.getItem('filters')) || {};
    filtersLocal.filter_by_vote = newSelected;
    localStorage.setItem('filters', JSON.stringify(filtersLocal));
    updateURLParams('filter_by_vote', newSelected);

    updateSelectedFilters({ type: newSelected });
  };

  const handleSelectAllSession = (isChecked) => {
    const allIds = filterCarf.options?.map((item) => item.id) || [];
    const newSelected = isChecked ? allIds : [];
    setSelectedSessionOptions(newSelected);

    let filtersLocal = JSON.parse(localStorage.getItem('filters')) || {};
    filtersLocal.filter_by_secao_carf = newSelected;
    localStorage.setItem('filters', JSON.stringify(filtersLocal));
    updateURLParams('filter_by_secao_carf', newSelected);

    updateSelectedFilters({ session: newSelected });
  };

  useEffect(() => {
    if (clear) {
      const decisionIds =
        filterFavorability.options?.map((item) => item.id) || [];

      setSelectedDecisionOptions(decisionIds);

      let filtersLocal = JSON.parse(localStorage.getItem('filters')) || {};
      filtersLocal.filter_by_favorability = decisionIds;
      localStorage.setItem('filters', JSON.stringify(filtersLocal));

      updateURLParams('filter_by_favorability', decisionIds);

      updateSelectedFilters({ decision: decisionIds });
    }
  }, [clear, filterFavorability, updateSelectedFilters]);

  useEffect(() => {
    toggleDecision();
  }, [filters]);

  return (
    <div className="bg-white px-6 mb-4 pt-[22px] pb-[22px] rounded-lg shadow w-full font-sans">
      <div className="title-filter">Filtros Tributários</div>
      <div className="flex justify-between items-center  mb-[8px] text-center cursor-pointer py-2 border-b bg-[#F9FAFB] h-8 rounded-sm">
        <span className="flex gap-2 items-center">
          <div className="item-collapse-entities item-collapse-entities-0">
            <span
              onClick={(e) => {
                e.stopPropagation();
                toggleDecision();
              }}
              className="cursor-pointer"
              style={{
                marginRight: '10px',
              }}
            >
              {isDecisionOpen ? (
                <ArrowDown2 color="#98A2B3" size={14} />
              ) : (
                <ArrowRight2 color="#98A2B3" size={14} />
              )}
            </span>

            <input
              type="checkbox"
              className="custom-checkbox-taxfilter"
              onChange={(e) => handleSelectAllDecision(e.target.checked)}
              checked={
                Array.isArray(filterFavorability.options) &&
                Array.isArray(selectedDecisionOptions) &&
                filterFavorability.options.every((item) =>
                  selectedDecisionOptions.includes(item.id),
                )
              }
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              ref={(el) => {
                if (el) {
                  el.indeterminate =
                    filterFavorability.options &&
                    selectedDecisionOptions?.length > 0 &&
                    selectedDecisionOptions?.length <
                      filterFavorability.options.length;
                }
              }}
            />
            {filterFavorability.public_name || 'Resultado da decisão'}
          </div>
        </span>
        <span
          className="entity-count-0"
          style={{
            marginRight: '12px', // TODO: Adiciona espaço no final dos grupos, pq o elemento não suporta
          }}
        >
          {AllProps?.generalCount?.favorabilidade
            ? AllProps?.generalCount?.favorabilidade.reduce(
                (acc, curr) => acc + (curr?.count ? curr.count : 0),
                0,
              )
            : 0}
        </span>
      </div>

      {isDecisionOpen && (
        <div className="box-content">
          {filterFavorability.options?.map((item, index) => {
            // os IDs do banco são 0 para contrario 1 parcial e etc
            // contudo como vem do filtro com outro valor somos obrigados a alterar

            let mappedId = -1;
            switch (item.name) {
              case 'Contrário ao contribuinte':
                mappedId = 0;
                break;
              case 'Parcialmente favorável ao contribuinte':
                mappedId = 1;
                break;
              case 'Favorável ao contribuinte':
                mappedId = 2;
                break;
              case 'Outros/Indeterminado':
                mappedId = 3;
                break;
              case 'Não Classificado':
                mappedId = 4;
                break;
            }

            const countItem = AllProps?.generalCount?.favorabilidade?.find(
              (favItem) => favItem.name === mappedId,
            );

            return (
              <div
                key={index}
                className="item-collapse-entities item-collapse-entities-1"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    value={item.id}
                    checked={selectedDecisionOptions?.includes(item.id)}
                    onChange={() => handleCheckboxChange(item.id, 'decision')}
                  />
                  <span
                    className={`${selectedDecisionOptions?.includes(item.id) ? 'tax-filter-selected' : ''}`}
                  >
                    {item.name}
                  </span>
                </label>
                {/* Adicionar o valor de count (se disponível) */}
                <span className="single-entity-count">
                  {countItem ? countItem?.count : 0}
                </span>
              </div>
            );
          })}
        </div>
      )}

      <div className="flex justify-between items-center mt-2 mb-[8px] text-center cursor-pointer py-2 border-b bg-[#F9FAFB] h-8 rounded-sm">
        <div className="item-collapse-entities item-collapse-entities-0">
          <span className="flex gap-2 items-center">
            {isTypeOpen ? (
              <ArrowDown2 color="#98A2B3" size={14} onClick={toggleType} />
            ) : (
              <ArrowRight2 color="#98A2B3" size={14} onClick={toggleType} />
            )}
            <input
              type="checkbox"
              className="custom-checkbox-taxfilter"
              onChange={(e) => handleSelectAllType(e.target.checked)}
              checked={
                Array.isArray(filterVote.options) &&
                Array.isArray(selectedTypeOptions) &&
                filterVote.options.every((item) =>
                  selectedTypeOptions.includes(item.id),
                )
              }
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              ref={(el) => {
                if (el) {
                  el.indeterminate =
                    filterVote.options &&
                    selectedTypeOptions?.length > 0 &&
                    selectedTypeOptions?.length < filterVote.options.length;
                }
              }}
            />
            {filterVote.public_name} (CARF)
          </span>
        </div>
        <span
          className="entity-count-0"
          style={{
            marginRight: '12px', // TODO: Adiciona espaço no final dos grupos, pq o elemento não suporta
          }}
        >
          {AllProps?.generalCount?.voto
            ? AllProps?.generalCount?.voto.reduce(
                (acc, curr) => acc + (curr?.count ? curr.count : 0),
                0,
              )
            : 0}
        </span>
      </div>

      {isTypeOpen && (
        <div className="box-content">
          {filterVote.options?.map((item) => {
            // Encontrar o objeto correspondente
            const countItem = AllProps?.generalCount?.voto?.find(
              (voto) => voto.name === item.name,
            );

            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                key={item.id}
                className="item-collapse-entities item-collapse-entities-1"
              >
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    value={item.id}
                    checked={selectedTypeOptions?.includes(item.id)}
                    onChange={() => handleCheckboxChange(item.id, 'type')}
                    className="w-4 h-4 cursor-pointer rounded-full border-[4px] border-gray-300"
                  />
                  <span
                    className={`${selectedTypeOptions?.includes(item.id) ? 'tax-filter-selected' : ''}`}
                  >
                    {item.name}
                  </span>
                </label>
                <span className="single-entity-count">
                  {countItem ? countItem?.count : 0}
                </span>
              </div>
            );
          })}
        </div>
      )}

      <div className=" flex justify-between items-center mt-2  text-center cursor-pointer py-2 border-b bg-[#F9FAFB] h-8 rounded-sm">
        <div className="item-collapse-entities item-collapse-entities-0">
          <span className="flex gap-2 items-center">
            {toggleSessionOpen ? (
              <ArrowDown2 color="#98A2B3" size={14} onClick={SessionOpen} />
            ) : (
              <ArrowRight2 color="#98A2B3" size={14} onClick={SessionOpen} />
            )}
            <input
              className="custom-checkbox-taxfilter"
              type="checkbox"
              onChange={(e) => handleSelectAllSession(e.target.checked)}
              checked={
                Array.isArray(filterCarf.options) &&
                Array.isArray(selectedSessionOptions) &&
                filterCarf.options.every((item) =>
                  selectedSessionOptions.includes(item.id),
                )
              }
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              ref={(el) => {
                if (el) {
                  el.indeterminate =
                    filterCarf.options &&
                    selectedSessionOptions?.length > 0 &&
                    selectedSessionOptions?.length < filterCarf.options.length;
                }
              }}
            />
            Sessão (CARF)
          </span>
        </div>
        <span
          className="entity-count-0"
          style={{
            marginRight: '12px', // TODO: Adiciona espaço no final dos grupos para a contagem ficar alinhada com as demais
            // , pq o elemento não suporta flex diretamente
          }}
        >
          {AllProps?.generalCount?.favorabilidade
            ? AllProps?.generalCount?.secao_carf.reduce(
                (acc, curr) => acc + (curr?.count ? curr.count : 0),
                0,
              )
            : 0}
        </span>
      </div>

      {toggleSessionOpen && (
        <div className="box-content">
          {filterCarf.options?.map((item, index) => {
            // Encontrar o objeto correspondente em AllProps.generalCount.secao_carf
            const countItem = AllProps?.generalCount?.secao_carf?.find(
              (secaoItem) => secaoItem.secao === item.name,
            );

            return (
              <div
                key={index}
                className="item-collapse-entities item-collapse-entities-1"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    value={item.id}
                    checked={selectedSessionOptions?.includes(item.id)}
                    onChange={() => handleCheckboxChange(item.id, 'session')}
                  />
                  <span
                    className={`${selectedSessionOptions?.includes(item.id) ? 'tax-filter-selected' : ''}`}
                  >
                    {item.name}
                  </span>
                </label>
                <span className="single-entity-count">
                  {countItem ? countItem?.count : 0}{' '}
                </span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TaxFilters;
