import { DialogTitle } from '../../../components/ui/dialog';
import { Tooltip, Whisper } from 'rsuite';
import resume_inteiro_teor from '../../../assets/svgs/resume-inteiro-teor.svg';
import { InfoCircle } from 'iconsax-react';
import { useSelector } from 'react-redux';
import { ResumeInteiroTeorSkeleton } from '../../Skeleton/index';
import { UsefetchResumeIA } from '../../../api/resume-ia';
import './ResumeIABulkActionContext.css';
import { ResumeIABulkActionResult } from './ResumeIABulkActionResult/ResumeIABulkActionResult';
import { useCallback, useEffect, useState } from 'react';

export const ResumeIABulkActionContext = ({
  open,
  collection,
  currentPage,
  contextPage,
  countResults,
  firstCards,
  pageView,
  isItInACollection,
}) => {
  const [resultsTime, setResultsTime] = useState(null);
  const [sumarizablesCards, setSumarizablesCards] = useState(0);
  const [countCard, setCountCard] = useState(false);

  const theCards = useSelector(
    (state) => state.collectionsBulkaction.selectedCards,
  );
  const { mutate, data, isPending } = UsefetchResumeIA();

  const calcularTempoLeitura = useCallback((texto, velocidadeLeitura = 200) => {
    if (!texto) return 0;
    // Divide o texto em palavras
    const palavras = texto.trim().split(/\s+/);
    const totalPalavras = palavras.length;

    console.log(totalPalavras);

    // Calcula o tempo em minutos
    const tempoMinutos = totalPalavras / velocidadeLeitura;

    // Converte para minutos e segundos
    const minutos = Math.floor(tempoMinutos);

    if (minutos == 0 || minutos == 1) {
      return '1 minuto';
    }

    return `${minutos} minutos`;
  }, []);

  useEffect(() => {
    let startTime = new Date();
    let countSumarizablesCards = 0;
    if (contextPage == 'resume-in-result') {
      console.log('firstCards: ', firstCards.cards);
      setCountCard(false);
      firstCards.cards.slice(0, 12).map((card) => {
        if (card.sumarizable == true) {
          countSumarizablesCards += 1;
        }
      });
      const payload = {
        user_id: JSON.parse(localStorage.getItem('profile')).id,
        card_ids: firstCards.cards.slice(0, 12).map((card) => ({
          card_id: card.id,
          entity: card.entity,
          text: card.content[0].content,
          extras: {
            dataJul: card.dataJul,
            dataPub: card.dataPub,
            data_jul: card.data_jul,
            data_pub: card.data_pub,
            data_publicacao_date: card.data_publicacao_date,
            relator: card.relator,
            url: card.url,
            content: card.content,
          },
          kind: 'ementa',
        })),
      };

      setSumarizablesCards(countSumarizablesCards);
      mutate(payload, {
        onSuccess: (res) => {
          console.log('Resposta da API:', res);
          setResultsTime(((new Date() - startTime) / 1000).toFixed(0));
        },
        onError: (err) => {
          console.error('Erro ao buscar resumo:', err);
        },
      });
    } else {
      console.log('theCards: ', theCards);
      setCountCard(true);
      theCards.map((card) => {
        if (card.sumarizable == true) {
          countSumarizablesCards += 1;
        }
      });
      const payload = {
        user_id: JSON.parse(localStorage.getItem('profile')).id,
        card_ids: theCards.map((card) => ({
          card_id: card.id,
          entity: card.entity.id,
          text: card.content[0].content,
          extras: {
            dataJul: card.dataJul,
            dataPub: card.dataPub,
            data_jul: card.data_jul,
            data_pub: card.data_pub,
            data_publicacao_date: card.data_publicacao_date,
            relator: card.relator,
            url: card.url,
            content: card.content,
          },
          kind: 'ementa',
        })),
      };

      setSumarizablesCards(countSumarizablesCards);
      mutate(payload, {
        onSuccess: (res) => {
          console.log('Resposta da API:', res);
          setResultsTime(((new Date() - startTime) / 1000).toFixed(0));
        },
        onError: (err) => {
          console.error('Erro ao buscar resumo:', err);
        },
      });
    }
  }, [open]);

  return (
    <div className="resume-ia-bulkaction-context">
      <DialogTitle className="title-resume-ia">
        Turivius IA
        <img src={resume_inteiro_teor} />
        <Whisper
          trigger="hover"
          placement="top"
          speaker={
            <Tooltip>
              Estamos em desenvolvimento e aprimoramento da nossa IA
            </Tooltip>
          }
        >
          <div className="title-beta">Beta</div>
        </Whisper>
      </DialogTitle>
      <div className="info_resume_ia">
        <span className="info-icon-container">
          <InfoCircle size="16" variant="Bold" color="#0069F5" />
        </span>
        Essa é uma feature <span className="info-pro">Pro</span> Aproveite seu
        período de degustação
      </div>
      <div className="info_generate_insights-container">
        <div className="info_generate_insights">
          Gerando insights de{' '}
          <span>
            {' '}
            {sumarizablesCards} da
            {countCard
              ? theCards.length > 1
                ? 's'
                : ''
              : countResults > 1
                ? 's'
                : ''}{' '}
            {contextPage == 'resume-in-result'
              ? countResults >= 12
                ? 12
                : countResults
              : currentPage == 'collectionpage'
                ? collection.cards.length
                : theCards.length}{' '}
            {countCard
              ? theCards.length > 1
                ? 'decisões'
                : 'decisão'
              : countResults > 1
                ? 'decisões'
                : 'decisão'}{' '}
            selecionada
            {countCard
              ? theCards.length > 1
                ? 's'
                : ''
              : countResults > 1
                ? 's'
                : ''}
            .
          </span>
          <Whisper
            trigger="hover"
            placement="left"
            speaker={
              <Tooltip
                style={{
                  display: 'flex',
                  minWidth: '246px',
                  minHeight: '48px',
                  alignItems: 'center',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: '400',
                }}
              >
                <InfoCircle
                  size="26"
                  variant="Linear"
                  style={{ marginRight: '6px' }}
                />
                {countCard
                  ? theCards.length > 1
                    ? 'Das'
                    : 'De'
                  : countResults > 1
                    ? 'Das'
                    : 'De'}{' '}
                {contextPage == 'resume-in-result'
                  ? countResults >= 12
                    ? 12
                    : countResults
                  : currentPage == 'collectionpage'
                    ? collection.cards.length
                    : theCards.length}{' '}
                {countCard
                  ? theCards.length > 1
                    ? 'decisões'
                    : 'decisão'
                  : countResults > 1
                    ? 'decisões'
                    : 'decisão'}{' '}
                selecionada
                {countCard
                  ? theCards.length > 1
                    ? 's'
                    : ''
                  : countResults > 1
                    ? 's'
                    : ''}
                , temos o resumo para {sumarizablesCards}{' '}
                {countCard
                  ? theCards.length > 1
                    ? 'decisões'
                    : 'decisão'
                  : countResults > 1
                    ? 'decisões'
                    : 'decisão'}
              </Tooltip>
            }
          >
            <InfoCircle size="16" variant="Bold" color="#0069F5" />
          </Whisper>
        </div>
        Tempo de leitura estimado:
        <span>
          {' '}
          {calcularTempoLeitura(
            data?.data.summary_fatos + data?.data.summary_decisao,
          )}
        </span>
      </div>
      {isPending && <ResumeInteiroTeorSkeleton />}
      {!isPending && (
        <ResumeIABulkActionResult
          resultData={data}
          theCards={theCards}
          firstCards={firstCards}
          pageView={pageView}
          isItInACollection={isItInACollection}
        />
      )}
    </div>
  );
};
