import { useEffect } from 'react';
import { Radio, RadioGroup } from 'rsuite';
import { posthog } from '../../../../../../../requesters';
import { postHubSpotEvent } from '../../../../../../../services/hubspot';
import './DefaultOperatorFilter.css';

const DefaultOperatorFilter = (props) => {
  const handleChange = (newOption) => {
    const { context, filtersProps, setDefaultOperator } = props;
    if (context) {
      posthog.capture(context, {
        action: 'change_parameter',
        id: 'default_operator',
      });
      postHubSpotEvent('app_changeparameter', { app_changeparameter: context });
    }

    if (context === 'Monitoring') {
      setDefaultOperator(newOption);
    } else {
      filtersProps.changeDefaultOperator(newOption);
    }
  };

  const currentValue =
    props.context !== 'Monitoring'
      ? props.filtersProps.defaultOperator
      : props.defaultOperator;

  useEffect(() => {
    if (props.context != 'Monitoring') {
      if (!props.filtersProps.defaultOperator) {
        props.filtersProps.changeDefaultOperator('ou');
      }
    }
  });

  return (
    <div className="default-operator-filter">
      <span className="title-option">Operador de Busca Padrão</span>

      <div className="default-operator-button-group">
        <button
          type="button"
          className={`default-operator-button ${
            currentValue === 'ou' ? 'active' : ''
          }`}
          onClick={() => handleChange('ou')}
        >
          Pelo menos um termo
          <br />
          (OU)
        </button>
        <button
          type="button"
          className={`default-operator-button ${
            currentValue === 'e' ? 'active' : ''
          }`}
          onClick={() => handleChange('e')}
        >
          Todos os termos
          <br />
          (E)
        </button>
      </div>
    </div>
  );
};

export default DefaultOperatorFilter;
