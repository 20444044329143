import { Whisper, Tooltip } from 'rsuite';
import FiltersList from '../FilterList/FilterList';
import './advancedFilters.scss';
import { CloseCircle } from 'iconsax-react';
import { useRef, useState } from 'react';
import { CheckboxList } from '../FilterList/Filters/RecursiveFilter/RecursiveChildren';

const AdvancedFilters = (props) => {
  const [RecursiveChildrenOpen, setRecursiveChildrenOpen] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isMouseEntered, setIsMouseEntered] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // Estado para armazenar o valor do input
  const [selectedRecurse, setSelectedRecurse] = useState('');
  const [filtersDataRecursive, setFiltersDataRecursive] = useState(null);
  const [filtersProps, setFiltersProps] = useState(null);
  const [isCheckedsRecursives, setIsCheckedsRecursives] = useState([]);
  const tooltipRef = useRef(null);

  const drawerClass = props.open ? 'drawer open' : 'drawer';

  const handleDrawerClick = (e) => {
    e.stopPropagation();
  };

  const handleMouseMove = (event) => {
    if (!isMouseEntered && tooltipRef.current) {
      const mouseY = event.clientY;
      const windowHeight = window.innerHeight;
      const tooltipHeight = tooltipRef.current.getBoundingClientRect().height;
      const offset = Math.min(0, tooltipHeight / 2);

      let newTop = mouseY + offset;

      if (newTop + tooltipHeight > windowHeight && window.innerWidth > 1279) {
        newTop = windowHeight - tooltipHeight;
      }

      if (newTop < 0) {
        newTop = 0;
      }

      setMousePosition({
        x: event.clientX,
        y: newTop,
      });
      setIsMouseEntered(true);
    }
  };

  const filteredDataOption =
    filtersDataRecursive && Array.isArray(filtersDataRecursive[9]?.options)
      ? filtersDataRecursive[9].options
      : filtersProps;

  const groupData = {};

  filteredDataOption &&
    filteredDataOption.forEach((item) => {
      if (!groupData[item.group]) {
        groupData[item.group] = [];
      }
      groupData[item.group].push(item);
    });

  return (
    <div style={{ cursor: 'default' }} onClick={handleDrawerClick}>
      <div className={drawerClass}>
        <button
          className="button-drawer"
          onClick={props.onClose}
          style={{ float: 'right' }}
        >
          <CloseCircle size="24" color="#292D32" />
        </button>

        <div className="header-filter-home">
          <h1>Filtros Avançados</h1>
        </div>

        <div className="drawer-content">
          <FiltersList
            AllProps={props.AllProps}
            tmpFilters={props.tmpFilters}
            filtersProps={props.filtersProps}
            entities={props.entities}
            clearSelectedFilters={props.clearFilters}
            query={props.query}
            isFilterHome={true}
            currentPage={'homepage'}
            setRecursiveChildrenOpen={setRecursiveChildrenOpen}
            handleMouseMove={handleMouseMove}
            setIsMouseEntered={setIsMouseEntered}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setFiltersDataRecursive={setFiltersDataRecursive}
            setSelectedRecurse={setSelectedRecurse}
            filter={props.filter}
            setFiltersProps={setFiltersProps}
            groupData={groupData}
            isCheckedsRecursives={isCheckedsRecursives}
            setIsCheckedsRecursives={setIsCheckedsRecursives}
            filterPropsFilterList={props.filterPropsFilterList}
          />
        </div>
        {RecursiveChildrenOpen && (
          <div
            className="fixed"
            ref={tooltipRef}
            style={{
              top: mousePosition.y - 20,
              right: '400px',
              zIndex: 500,
            }}
          >
            <CheckboxList
              searchTerm={searchTerm}
              setRecursiveChildrenOpen={setRecursiveChildrenOpen}
              setIsMouseEntered={setIsMouseEntered}
              filtersDataRecursive={filtersDataRecursive}
              selectedRecurse={selectedRecurse}
              filtersProps={props.filtersProps}
              groupData={groupData}
              isCheckedsRecursives={isCheckedsRecursives}
              setIsCheckedsRecursives={setIsCheckedsRecursives}
            />
          </div>
        )}
      </div>
      {props.open && <div className="backdrop" onClick={props.onClose} />}
    </div>
  );
};

export default AdvancedFilters;
