import { Radio, RadioGroup } from 'rsuite';
import { posthog } from '../../../../../../../requesters';
import { postHubSpotEvent } from '../../../../../../../services/hubspot';
import SynonymsFilter from '../SynonymsFilter';
import { useState, useEffect } from 'react';
import { ArrowDown2, ArrowUp2, TickCircle } from 'iconsax-react';
import './InteiroTeorFilter.scss';

const InteiroTeorFilter = ({
  context,
  filtersProps,
  updateSelectedFilters,
  resultById,
  clear,
  setClear,
  AllProps,
  setSynonymsConfirm,
  setClearForChildren,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [synonyms, setSynonyms] = useState(true);
  const [recursosRepetitivos, setRecursosRepetitivos] = useState(false);
  const [repercussaoGeral, setRepercussaoGeral] = useState(false);
  const [controversia, setControversia] = useState(false);
  const [selectedValue, setSelectedValue] = useState('["ementa","full_text"]');

  useEffect(() => {
    const field = localStorage.getItem('fields');
    if (field) {
      if (
        field === '["ementa","full_text"]' ||
        field === '["ementa"]' ||
        field === '["full_text"]'
      ) {
        setSelectedValue(field);
      }
    }
    if (resultById) {
      if (resultById === 'ementa,full_text') {
        setSelectedValue('["ementa","full_text"]');
      } else if (resultById === 'ementa') {
        setSelectedValue('["ementa"]');
      } else if (resultById === 'full_text') {
        setSelectedValue('["full_text"]');
      }
    }
  }, [resultById]);

  const changeSynonyms = (value) => {
    //TODO: Aqui os sinônimos são mudados
    setSynonyms(value);
    setSynonymsConfirm(value);

    // change state
    filtersProps.changeSynonyms(value);

    // change urlParams
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('synonyms', value);

    let paramsStringDecode = decodeURIComponent(urlParams);

    if (window.location.pathname == '/pesquisa/resultados') {
      // colocar na URL
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${paramsStringDecode}`,
      );
    }
  };

  const changeRecursosRepetitivos = (value) => {
    setRecursosRepetitivos(value);
  };

  const changeRepercussaoGeral = (value) => {
    setRepercussaoGeral(value);
  };

  const changeControversia = (value) => {
    setControversia(value);
  };

  //Função para filtrar com base nos escolhidos
  function OnChangeFilter(option) {
    //TODO: Check this point
    if (context) {
      posthog.capture(context, {
        action: 'change_parameter',
        id: 'ementa_inteiro_teor',
      });
      postHubSpotEvent('app_changeparameter', { app_changeparameter: context });
    }
    localStorage.setItem('fields', option);
    const parsedOption = JSON.parse(option);

    filtersProps.changeInteiroTeor(parsedOption);
    updateSelectedFilters({ inteiroTeor: parsedOption });

    const urlParams = new URLSearchParams(window.location.search);

    // TODO: Para não ficar aquela url zuada
    urlParams.set('fields', decodeURIComponent(option));

    // TODO: Para não ficar aquela url zuada
    let paramsStringDecode = decodeURIComponent(urlParams);

    if (window.location.pathname == '/pesquisa/resultados') {
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${paramsStringDecode}`,
      );
    }
    setSelectedValue(option);
  }

  //Quando da clear (clicka no "Limpar Filtros") esse UseEffect é ativado
  useEffect(() => {
    if (clear) {
      localStorage.setItem('fields', '["ementa","full_text"]');
      const parsedOption = JSON.parse('["ementa","full_text"]');

      filtersProps.changeInteiroTeor(parsedOption);
      updateSelectedFilters({ inteiroTeor: parsedOption });

      const urlParams = new URLSearchParams(window.location.search);

      urlParams.set('fields', decodeURIComponent('["ementa","full_text"]'));

      let paramsStringDecode = decodeURIComponent(urlParams);

      if (window.location.pathname == '/pesquisa/resultados') {
        window.history.replaceState(
          {},
          '',
          `${window.location.pathname}?${paramsStringDecode}`,
        );
      }

      setSelectedValue('["ementa","full_text"]');
      setClear(false);
    }
  }, [clear]);

  return (
    <div className="single-filter-box">
      <div className="filter-header" onClick={() => setCollapsed(!collapsed)}>
        <label>Buscar em</label>
        {collapsed ? (
          <ArrowDown2 color="#98A2B3" />
        ) : (
          <ArrowUp2 color="#98A2B3" />
        )}
      </div>
      <RadioGroup
        inline
        name="radioList"
        style={{ display: 'flex', gap: '0px', marginLeft: '2px' }}
        onChange={(option) => OnChangeFilter(option)}
        value={selectedValue}
      >
        <label className="label-radio-btn">
          <Radio value='["ementa","full_text"]'>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              {selectedValue == '["ementa","full_text"]' ? (
                <TickCircle size="12" />
              ) : (
                ''
              )}
              Todos
            </div>
          </Radio>
        </label>
        <label className="label-radio-btn">
          <Radio value='["ementa"]'>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              {selectedValue == '["ementa"]' ? <TickCircle size="12" /> : ''}
              Ementa
            </div>
          </Radio>
        </label>
        <label className="label-radio-btn">
          <Radio value='["full_text"]'>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              {selectedValue == '["full_text"]' ? <TickCircle size="12" /> : ''}
              Inteiro Teor
            </div>
          </Radio>
        </label>
      </RadioGroup>

      <div className="synonymsFilter-styles">
        {!collapsed && (
          <SynonymsFilter
            context="Results"
            filtersProps={{
              synonyms,
              recursosRepetitivos,
              repercussaoGeral,
              controversia,
              changeSynonyms,
              changeRecursosRepetitivos,
              changeRepercussaoGeral,
              changeControversia,
            }}
            AllProps={AllProps}
            clear={clear}
          />
        )}
      </div>
    </div>
  );
};

export default InteiroTeorFilter;
