import {
  InfoCircle,
  DocumentCopy,
  Document,
  Judge,
  Message,
  Copy,
  TickCircle,
  ArchiveAdd,
  ArchiveTick,
  Dislike,
  Like1,
  Book1,
  Profile2User,
  BagTick,
  Firstline,
  TableDocument,
  Link1,
} from 'iconsax-react';
import { Tooltip, Whisper } from 'rsuite';
import './ResumeIABulkActionResult.css';
import { useCallback, useEffect, useState } from 'react';
import { wait } from '../../../../utils/wait';
import { FloatingUI } from '../../../../components/ui/float';
import documentNormalIcon from '../../../../assets/svgs/document-normal-white.svg';
import copiedClipIcon from '../../../../assets/svgs/copied_cliped.svg';
import aiicon from '../../../../assets/svgs/AI-icon2.svg';
import { PostHogSendResumeIa } from '../../../../utils/PostHogSendEvents';
import TextToSpeechPlayer from '../TextToSpeechPlayer';
import SaveCardToCollectionButton from '../../../../modules/jurisprudential-search/components/results/Buttons/SaveCardToCollectionButton';
import { Button } from '../../../../ds-components';

export const ResumeIABulkActionResult = ({
  resultData,
  theCards,
  firstCards,
  pageView,
  isItInACollection = false,
}) => {
  const [textToCopy, setTextToCopy] = useState('');
  const [copied, setCopied] = useState(false);
  const [fullCopied, setFullCopied] = useState(false);
  const [copied1, setCopied1] = useState(false);
  const [copied2, setCopied2] = useState(false);
  const [copied3, setCopied3] = useState(false);
  const [copied4, setCopied4] = useState(false);
  const [fullCopiedFooter, setFullCopiedFooter] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredCopy1, setIsHoveredCopy1] = useState(false);
  const [isHoveredCopy2, setIsHoveredCopy2] = useState(false);
  const [isHoveredCopy3, setIsHoveredCopy3] = useState(false);
  const [isHoveredCopy4, setIsHoveredCopy4] = useState(false);
  const [userOpnion, setUserOpnion] = useState(null);

  const handleCopy = useCallback(async () => {
    navigator.clipboard.writeText(textToCopy || '');
    setCopied(true);
    PostHogSendResumeIa('copy_functions_resume', 'copy_excerpt');
    await wait(5000);
    setCopied(false);

    setTextToCopy('');
  }, [textToCopy]);

  const handleFullCopy = useCallback(async (text1, text2) => {
    let formattedText = '';

    if (text1 && text2) {
      formattedText = `As decisões falam sobre:\n${text1}\n\n\nDecisão Final:\n${text2}`;
      setFullCopiedFooter(true);
    } else if (text1) {
      formattedText = text1;
    } else if (text2) {
      formattedText = text2;
    }

    navigator.clipboard.writeText(formattedText.trim());
    setFullCopied(true);
    await wait(5000);
    setFullCopied(false);
    setFullCopiedFooter(false);
    setCopied1(false);
    setCopied2(false);
    setCopied3(false);
    setCopied4(false);
  }, []);

  useEffect(() => {
    setCopied(false);
  }, [textToCopy]);

  const handleFeedback = useCallback((action) => {
    setUserOpnion(action);
  });

  return (
    <>
      <div className="button-input-container">
        <button>
          <span className="btn-left-resume">
            {' '}
            <DocumentCopy size="12" variant="Outline" color="#475467" /> Resumo
            geral
          </span>
        </button>
        <button disabled>
          <span className="btn-right-resume">
            {' '}
            <Document size="12" variant="Outline" color="#475467" /> Resumo
            individual
          </span>
        </button>
        <div style={{ marginBottom: '10px' }}>
          <Whisper
            trigger="hover"
            placement="left"
            speaker={
              <Tooltip
                style={{
                  display: 'flex',
                  minWidth: '246px',
                  minHeight: '48px',
                  alignItems: 'center',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: '400',
                }}
              >
                <InfoCircle
                  size="26"
                  variant="Linear"
                  style={{ marginRight: '6px' }}
                />
                Em breve vamos exibir também os resumos individuais
              </Tooltip>
            }
          >
            <div className="title-em-breve">Em Breve</div>
          </Whisper>
        </div>
      </div>
      <div className="container-results-resume-ia">
        <div className="container-about-desicion">
          <div className="title-about-decision">
            {/* <Message size="14" color="#292D32" /> */}
            <img
              src={aiicon}
              style={{
                width: '16px',
                height: '16px',
              }}
            />
            Análise
          </div>
          <Whisper
            trigger="hover"
            placement="left"
            speaker={
              <Tooltip
                style={{
                  gap: '8px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  height: '32px',
                  width: '163px',
                }}
              >
                <Copy size="12" color="#ffffff" />
                Copiar este resumo
              </Tooltip>
            }
          >
            <div
              className={`copy-style ${copied3 ? ' copied-style' : ''}`}
              onClick={() => {
                handleFullCopy(resultData?.data.summary_fatos);
                setCopied3(true);
                PostHogSendResumeIa(
                  'copy_functions_resume',
                  'copy_summary',
                  'about_decision',
                );
              }}
              onMouseEnter={() => setIsHoveredCopy3(true)}
              onMouseLeave={() => setIsHoveredCopy3(false)}
            >
              <Copy
                size="16"
                color={copied3 || isHoveredCopy3 ? '#12B76A' : '#475467'}
                style={{
                  rotate: '180deg',
                }}
              />

              {copied3 ? (
                <span style={{ marginLeft: '8px' }}>Copiado</span>
              ) : (
                ''
              )}
            </div>
          </Whisper>
          <TextToSpeechPlayer
            text={resultData?.data?.summary_fatos}
            tooltip={'Ouvir sobre os fatos'}
          ></TextToSpeechPlayer>
          <div className="context-result">
            <div className="selection-style-resume">
              {resultData?.data.summary_fatos
                ?.split('\n')
                .map((theText, index) => (
                  <>
                    <span style={{ fontSize: '16px' }} key={index}>
                      {theText}
                    </span>
                    <br></br>
                  </>
                ))}
            </div>
            <FloatingUI getTextSelected={setTextToCopy}>
              <>
                <button
                  onClick={handleCopy}
                  disabled={copied}
                  className="gap-2 flex items-center px-3 py-2 rounded border border-[#344054]  bg-[#101828] hover:opacity-100"
                  style={{ color: 'white' }}
                >
                  {copied ? (
                    <>
                      <TickCircle size={12} color="white" />
                      Copiado
                    </>
                  ) : (
                    <>
                      <Copy size={12} color="white" />
                      Copiar
                    </>
                  )}
                </button>
                <div className="left-1/2 absolute bottom-0 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-2 h-2 bg-[#101828] border-r border-b border-solid border-[#344054]" />
              </>
            </FloatingUI>
          </div>
        </div>

        <div className="container-decision-final">
          <div className="title-decision-final">
            {' '}
            <Judge size="14" color="#292D32" />
            Decisão Final
          </div>

          <Whisper
            trigger="hover"
            placement="left"
            speaker={
              <Tooltip
                style={{
                  gap: '8px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  height: '32px',
                  width: '163px',
                }}
              >
                <Copy size="12" color="#ffffff" />
                Copiar este resumo
              </Tooltip>
            }
          >
            <div
              className={`copy-style ${copied4 ? ' copied-style' : ''}`}
              onClick={() => {
                handleFullCopy(resultData?.data.summary_decisao);
                PostHogSendResumeIa(
                  'copy_functions_resume',
                  'copy_summary',
                  'final_decision',
                );
                setCopied4(true);
              }}
              onMouseEnter={() => setIsHoveredCopy4(true)}
              onMouseLeave={() => setIsHoveredCopy4(false)}
            >
              <Copy
                size="16"
                color={copied4 || isHoveredCopy4 ? '#12B76A' : '#475467'}
                style={{
                  rotate: '180deg',
                }}
              />

              {copied4 ? (
                <span style={{ marginLeft: '8px' }}>Copiado</span>
              ) : (
                ''
              )}
            </div>
          </Whisper>
          <div className="context-result-dec-final">
            <div className="selection-style-resume">
              {resultData?.data.summary_decisao
                ?.split('\n')
                .map((theText, index) => (
                  <>
                    <span style={{ fontSize: '16px' }} key={index}>
                      {theText}
                    </span>
                    <br></br>
                  </>
                ))}
            </div>
          </div>
        </div>
        <div className="container-topics">
          <div className="title-topics">
            {' '}
            <TableDocument size="14" color="#292D32" />
            Assuntos tratados
          </div>
          <Whisper
            trigger="hover"
            placement="left"
            speaker={
              <Tooltip
                style={{
                  gap: '8px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  height: '32px',
                  width: '163px',
                }}
              >
                <Copy size="12" color="#ffffff" />
                Copiar este resumo
              </Tooltip>
            }
          >
            <div
              className={`copy-style ${copied1 ? ' copied-style' : ''}`}
              onClick={() => {
                handleFullCopy(resultData?.data.summary_topics);
                PostHogSendResumeIa(
                  'copy_functions_resume',
                  'copy_summary',
                  'final_decision',
                );
                setCopied1(true);
              }}
              onMouseEnter={() => setIsHoveredCopy1(true)}
              onMouseLeave={() => setIsHoveredCopy1(false)}
            >
              <Copy
                size="16"
                color={copied1 || isHoveredCopy1 ? '#12B76A' : '#475467'}
                style={{
                  rotate: '180deg',
                }}
              />
              {copied1 ? (
                <span style={{ marginLeft: '8px' }}>Copiado</span>
              ) : (
                ''
              )}
            </div>
          </Whisper>
          <div className="context-result-topics">
            <div className="selection-style-resume">
              <ul
                style={{
                  listStyleType: 'disc',
                  paddingLeft: '10px',
                  fontSize: '16px',
                  display: 'flex', // Faz os itens ficarem na mesma linha
                  flexWrap: 'wrap', // Permite que quebrem de forma natural se necessário
                  gap: '10px', // Espaço entre os itens
                }}
              >
                {resultData?.data.summary_topics
                  ?.split('\n')
                  .filter((text) => text.trim() !== '') // Remove linhas vazias
                  .map((theText, index) => (
                    <li key={index} style={{ display: 'inline-block' }}>
                      • {theText}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="container-topics">
          <div className="title-topics">
            {' '}
            <Book1 size="14" color="#292D32" />
            Legislação citada
          </div>
          <Whisper
            trigger="hover"
            placement="left"
            speaker={
              <Tooltip
                style={{
                  gap: '8px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  height: '32px',
                  width: '163px',
                }}
              >
                <Copy size="12" color="#ffffff" />
                Copiar este resumo
              </Tooltip>
            }
          >
            <div
              className={`copy-style ${copied2 ? ' copied-style' : ''}`}
              onClick={() => {
                handleFullCopy(resultData?.data?.summary_law);
                setCopied2(true);
              }}
              onMouseEnter={() => setIsHoveredCopy2(true)}
              onMouseLeave={() => setIsHoveredCopy2(false)}
            >
              <Copy
                size="16"
                color={copied2 || isHoveredCopy2 ? '#12B76A' : '#475467'}
                style={{
                  rotate: '180deg',
                }}
              />

              {copied2 ? (
                <span style={{ marginLeft: '8px' }}>Copiado</span>
              ) : (
                ''
              )}
            </div>
          </Whisper>
          <div className="context-result-topics">
            <div className="selection-style-resume">
              {resultData?.data?.summary_law != '' ||
              resultData?.data?.summary_law ? (
                <ul
                  style={{
                    listStyleType: 'disc',
                    paddingLeft: '10px',
                    fontSize: '16px',
                    display: 'flex', // Faz os itens ficarem na mesma linha
                    flexWrap: 'wrap', // Permite que quebrem de forma natural se necessário
                    gap: '10px', // Espaço entre os itens
                  }}
                >
                  {resultData?.data.summary_law
                    ?.split('\n')
                    .filter((text) => text.trim() !== '') // Remove linhas vazias
                    .map((theText, index) => (
                      <li key={index} style={{ display: 'inline-block' }}>
                        • {theText}
                      </li>
                    ))}
                </ul>
              ) : (
                <>
                  <span style={{ fontSize: '16px' }}>
                    <p>Não foi possível identificar citações.</p>
                  </span>
                  <br></br>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="container-decision-final">
          <div className="title-about-decision">
            {' '}
            <Link1 size="14" color="#292D32" />
            Fontes
          </div>
          <div className="context-result-links">
            <div className="context-result-links">
              <ul
                style={{
                  listStyleType: 'disc',
                  // paddingLeft: '20px',
                }}
              >
                {theCards?.length != 0
                  ? theCards.map((card, index) => (
                      <li
                        key={index}
                        style={{
                          marginBottom: '4px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        •
                        <a
                          href={card?.url}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            fontSize: '16px',
                            maxWidth: '500px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            marginRight: '6px',
                            marginLeft: '4px',
                          }}
                          className="link-url-summary-source"
                        >
                          {card.name}
                        </a>
                        <Link1 size="18" color="#0069F5" />
                      </li>
                    ))
                  : firstCards?.cards?.map((card, index) => (
                      <li
                        key={index}
                        style={{
                          marginBottom: '4px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        •
                        <a
                          href={card?.url}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            fontSize: '16px',
                            maxWidth: '500px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            marginRight: '6px',
                            marginLeft: '4px',
                          }}
                          className="link-url-summary-source"
                        >
                          {card.nome}
                        </a>
                        <Link1 size="18" color="#0069F5" />
                      </li>
                    ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="action-btns-resume-ia-modal">
          <div className="action-btns-resume-ia-modal-left">
            <Whisper
              trigger="hover"
              placement="top"
              speaker={
                <Tooltip>
                  {fullCopiedFooter ? 'Copiado' : 'Copiar todo o resumo'}
                </Tooltip>
              }
            >
              <span>
                <Button
                  appearance="blue900"
                  onClick={() => {
                    handleFullCopy(
                      resultData?.data.summary_fatos,
                      resultData?.data.summary_decisao,
                    );
                    PostHogSendResumeIa('copy_functions_resume', 'copy_all ');
                  }}
                  disabled={fullCopiedFooter}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  className="h-[36px] w-[151px]"
                >
                  <span
                    style={{
                      display: 'flex',
                      gap: '4px',
                      justifyContent: 'center',
                      fontSize: '12px',
                    }}
                  >
                    {fullCopiedFooter ? (
                      <>
                        <TickCircle
                          size={16}
                          color="#12B76A"
                          style={{
                            marginTop: '1px',
                          }}
                        />
                        Copiado
                      </>
                    ) : isHovered ? (
                      <>
                        <img
                          src={copiedClipIcon}
                          style={{
                            width: '16px',
                            height: '16px',
                            marginTop: '1px',
                          }}
                        />
                        Copiar resumo
                      </>
                    ) : (
                      <>
                        <img
                          src={documentNormalIcon}
                          style={{
                            width: '16px',
                            height: '16px',
                            marginTop: '1px',
                          }}
                        />
                        Copiar resumo
                      </>
                    )}
                  </span>
                </Button>
              </span>
            </Whisper>
            <SaveCardToCollectionButton
              cards={theCards}
              query={'Coleção'}
              // theCollections={} It's used
              isItInACollection={isItInACollection}
              icon={
                false ? (
                  <div style={{ padding: 0 }}>
                    <ArchiveTick color="#32D583" size="19" variant="Bold" />
                  </div>
                ) : (
                  <div style={{ padding: 0 }}>
                    <ArchiveAdd color="#667085" size="19" />
                  </div>
                )
              }
              appearance="secondary"
              pageView={pageView}
              className={
                'max-w-fit h-9 py-2 px-4 border border-[#E4E7EC] rounded gap-2 flex items-center justify-center text-xs font-medium leading-5 text-[#475467]'
              }
              currentPageBulkAction={pageView}
            >
              Salvar na coleção
            </SaveCardToCollectionButton>
          </div>

          <div className="gap-1.5 flex items-center">
            <Whisper
              trigger="hover"
              placement="top"
              speaker={<Tooltip>Gostei</Tooltip>}
            >
              <button
                className="p-2 rounded border border-[#E4E7EC] flex justify-center items-center"
                style={{
                  backgroundColor: userOpnion === 'like' ? '#E4E7EC' : '#fff',
                }}
                onClick={() => handleFeedback('like')}
                // disabled={!(isSuccessSumary && isSuccessResumeIA)}
              >
                <Like1
                  size={16}
                  color={userOpnion === 'like' ? '#12B76A' : '#667085'}
                />
              </button>
            </Whisper>

            <Whisper
              trigger="hover"
              placement="top"
              speaker={<Tooltip>Não gostei</Tooltip>}
            >
              <button
                className="p-2 rounded border border-[#E4E7EC] flex justify-center items-center"
                onClick={() => handleFeedback('unlike')}
                style={{
                  backgroundColor: userOpnion === 'unlike' ? '#E4E7EC' : '#fff',
                }}
                // disabled={!(isSuccessSumary && isSuccessResumeIA)}
              >
                <Dislike
                  size={16}
                  color={userOpnion === 'unlike' ? '#FDB022' : '#667085'}
                />
              </button>
            </Whisper>
          </div>
        </div>

        <span className="end-desc-result-resume-ia">
          Toda IA pode cometer erros. Cheque informações importantes
        </span>
      </div>
    </>
  );
};
