import React from 'react';
import {
  Input,
  InputGroup,
  Message,
  Grid,
  Row,
  Col,
  toaster,
  Whisper,
  Popover,
  Button,
  Tooltip,
} from 'rsuite';

import { requester } from '../../../../../requesters';
import { withRouter } from 'react-router-dom';
import OperatorsHelper from '../OperatorsHelper';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import {
  SearchNormal1,
  People,
  ArrowDown2,
  ArrowUp2,
  TickCircle,
  Clock,
} from 'iconsax-react';
import { connect } from 'react-redux';
import OperatorsHelperOpen from '../OperatorsHelperOpen';
import './SearchBarResults.css';

import { PostHogSendSearch } from '../../../../../utils/PostHogSendEvents';
import CustomerXTracking from '../../../../../utils/CustomerXTracking';

const SEARCH_MODE = {
  NORMAL: 'normal',
  PROCESS_NUMBER: 'nproc',
};

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      filters: '',
      options: [],
      isSearchByNumber: false,
      showSearchTypeOptions: false,
      buttonTitle: 'Buscar',
      openSearch: false,
      selectedIndex: -1,
      previousQuery: '',
    };
    this.input = React.createRef();
    this.searchInputRef = React.createRef();
    this.searchTypeRef = React.createRef();
    this.addOperator = this.addOperator.bind(this);
    this.searchTypeContainerRef = React.createRef(); // Essa é a ref para poder fzer o searchmodeBOx sumir
    // Para controlar o debounce
    this.debounceTimerRef = React.createRef(null);
    this.userId = localStorage.getItem('user') || 0;
    this.companyId = localStorage.getItem('company') || 0;
  }

  setSelectedIndex = (index) => {
    this.setState({ selectedIndex: index });
  };

  handleChangeQuery = (value) => {
    this.setState({ query: value, selectedIndex: -1 });

    // Se já existir um timeout ativo, limpa ele
    if (this.debounceTimerRef.current) {
      clearTimeout(this.debounceTimerRef.current);
    }

    // Define um novo timeout para fazer a requisição após 500ms sem digitação
    this.debounceTimerRef.current = setTimeout(() => {
      if (value.trim().length >= 3) {
        requester.cards
          .responseSeacrhInput({
            query: value,
            userId: this.userId,
            companyId: this.companyId,
          })
          .then((response) =>
            this.setState({
              options: Array.isArray(response) ? response : [],
            }),
          )
          .catch((err) => {
            console.error(err);
            this.setState({ options: [] });
          });
      } else {
        this.setState({ options: [] });
      }
    }, 300); // 300ms de debounce
  };

  handleKeyDown = (e) => {
    const { selectedIndex, options, query, initialQuery } = this.state;

    if (e.key === 'ArrowDown') {
      e.preventDefault();

      if (selectedIndex === -1) {
        this.setState({ initialQuery: query });
      }

      this.setState((prevState) => {
        const nextIndex =
          prevState.selectedIndex < options.length - 1
            ? prevState.selectedIndex + 1
            : 0;
        const newQuery = options[nextIndex]?.text || prevState.query;
        return { selectedIndex: nextIndex, query: newQuery };
      });
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();

      if (options.length === 0 || selectedIndex === -1) {
        return;
      }

      this.setState((prevState) => {
        const nextIndex =
          prevState.selectedIndex > 0 ? prevState.selectedIndex - 1 : -1;

        const newQuery =
          nextIndex === -1
            ? prevState.initialQuery || prevState.query
            : options[nextIndex]?.text;
        return { selectedIndex: nextIndex, query: newQuery };
      });
    } else if (e.key === 'Enter') {
      e.preventDefault();

      if (selectedIndex >= 0 && selectedIndex < options.length) {
        const selectedOption = options[selectedIndex].text;
        this.setState({ query: selectedOption });
        this.doSearch();
      } else {
        const searchTerm = query.trim();
        if (searchTerm.length >= 3) {
          this.doSearch();
          this.props.setIsSearching();
        } else {
          toaster.push(
            <Message duration={4000} type="warning" showIcon closable>
              Sua busca deve ter pelo menos três caracteres
            </Message>,
            { placement: 'topEnd' },
          );
        }
      }
      this.setState({ options: [], selectedIndex: -1, initialQuery: '' });
    }
  };

  doSearch = () => {
    const { setIsSearching } = this.props;
    setTimeout(() => {
      let { query } = this.state;

      if (query === null || query === undefined) {
        query = this.searchInputRef.current.value;
        this.setState({ query });
      }

      query = query.trim();

      if (query.length < 3) {
        toaster.push(
          <Message duration={4000} type="warning" showIcon closable>
            Sua busca deve ter pelo menos três caracteres
          </Message>,
        );
      } else {
        if (this.state.query !== this.state.previousQuery) {
          CustomerXTracking('action', 'pesquisas');
          this.setState({ previousQuery: this.state.query });
        }
        setIsSearching();
        const { dispatch } = this.props;
        this.props.dispatch({ type: 'UNSELECT_ALL' });

        if (this.state.query.toLowerCase().includes(' adj ')) {
          toaster.push(
            <Message
              position={'bottom'}
              header="O operador ADJ deve vir acompanhado de um número"
              duration={0}
              type="warning"
              showIcon
              closable
              style={{ marginBottom: '50px' }}
            >
              <div style={{ maxWidth: 320 }}>
                <Grid fluid>
                  <Row>
                    <Col xs={24}>
                      <p>
                        <b>Exemplo</b>
                        <br />
                        <i>
                          multa <b>ADJ2</b> ofício
                        </i>{' '}
                        irá retornar os resultados em que{' '}
                        <u>
                          {' '}
                          ofício será no máximo a segunda palavra após multa
                        </u>
                        .
                      </p>
                    </Col>
                    <Col xs={24}>
                      <blockquote>
                        [...] não pode ser exigida cumulativamente com a{' '}
                        <mark>multa</mark> de <mark>ofício</mark>, aplicável
                        [...]
                      </blockquote>
                    </Col>
                  </Row>
                </Grid>
              </div>
            </Message>,
          );
        } else {
          if (!this.props.filtersProps.filters) {
            this.props.filtersProps.loadFilters();
          } else {
            this.props.filtersProps.saveFilters(
              this.props.history,
              this.props.match,
              encodeURIComponent(this.state.query),
            );
            this.props.filtersProps.setQuery(encodeURIComponent(query));
          }
        }
      }
    }, 100);
  };

  verifyAndGetFilters = () => {
    if (
      this.props.originalFilters &&
      Object.keys(this.props.originalFilters).length === 0
    ) {
      requester.cards
        .getFilters()
        .then((response) => {
          const filters = response;
          let defaultFilters = {};
          let entityFilter = {};
          filters.forEach((filter) => {
            if (filter.field_name === 'entity') {
              defaultFilters.entities = filter.default;
              entityFilter = { ...filter };
            } else {
              defaultFilters[filter.field_name] = filter.default;
            }
          });
          this.props.filtersProps.setOriginalFilters(filters);
          this.props.filtersProps.setDefaultFilters(defaultFilters);
          this.props.filtersProps.loadFilters();
          this.props.filtersProps.restoreOptionalFilters();
          this.setState({ filter: entityFilter });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          if (this.mounted) {
            this.setState({ requesting: false });
          }
        });
    }
  };

  addOperator(operator) {
    let { query } = this.state;
    if (operator === '$') {
      query = `${query}${operator.trim()}`.replace('  ', ' ');
    } else {
      query = `${query} ${operator} `.replace('  ', ' ');
    }

    this.setState({ query });
    if (this.searchInputRef.current) {
      this.searchInputRef.current.focus();
    }
  }

  handlePopoverSelect = (mode) => {
    const title =
      mode === SEARCH_MODE.NORMAL ? 'Buscar' : 'Buscar por n° de processo';

    this.setState({
      buttonTitle: title,
      isSearchByNumber: mode === SEARCH_MODE.PROCESS_NUMBER,
      openSearch: false,
    });

    if (this.searchTypeRef.current) {
      this.searchTypeRef.current.close();
    }

    const placeholder =
      mode === SEARCH_MODE.PROCESS_NUMBER
        ? 'Insira o número do processo aqui'
        : 'Insira o termo de pesquisa aqui';

    if (this.searchInputRef.current) {
      this.searchInputRef.current.placeholder = placeholder;
    }

    this.handleSearchMode(mode);
  };

  handleClickOutside = (event) => {
    // EXPLANATION: Esse evento serve para que quando eu clicar no box de selecionar tipo de busca
    // e depois clicar em outro lugar na tela. O elemento do tipo de busca suma.
    if (
      this.searchTypeContainerRef.current &&
      !this.searchTypeContainerRef.current.contains(event.target)
    ) {
      this.setState({ openSearch: false });
    }
  };

  handleClearQuery = () => {
    this.setState({ query: '' });
    this.props.setQuery('');
    if (this.searchInputRef.current) {
      this.searchInputRef.current.focus();
    }
  };

  handleSearchMode = (value) => {
    //TODO: Aqui os searchmode é mudado
    // change state
    this.props.filtersProps.changeSearchMode(value);

    // change urlParams
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('search_mode', value);
    localStorage.setItem('search_mode', value); //Aqui atualiza o localStorage para o tipo de pesquisa que ira ser usado (normal ou nproc)

    let paramsStringDecode = decodeURIComponent(urlParams);

    if (window.location.pathname == '/pesquisa/resultados') {
      // colocar na URL
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${paramsStringDecode}`,
      );
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    const typeFilter = localStorage.getItem('search_mode');

    if (typeFilter == SEARCH_MODE.NORMAL) {
      this.setState({ buttonTitle: 'Busca' });
    } else {
      this.setState({ buttonTitle: 'Buscar por n° de processo' });
    }

    this.verifyAndGetFilters();
    this.mounted = true;
    if (this.props.location.search !== '') {
      const params = new URLSearchParams(this.props.location.search);
      const query = params.get('query');
      this.setState({ query });
      this.props.filtersProps.setQuery(query);
    }

    this.props.history.listen((location) => {
      if (this.mounted) {
        if (
          location.pathname.includes('resultados') &&
          location.search !== ''
        ) {
          const params = new URLSearchParams(location.search);
          const query = params.get('query');
          this.setState({ query });
        }
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    this.mounted = false;
  }

  toggleopenSearch = () => {
    this.setState((prevState) => ({
      openSearch: !prevState.openSearch,
    }));
  };

  render() {
    const { style, salueStyles } = this.props;
    const { options, buttonTitle, isSearchByNumber, openSearch } = this.state;
    const { query } = this.props;

    // Essa função gerar as opções com highligth. Talvez fosse melhor gerar no backend isso
    const highlightText = (text, searchTerm) => {
      if (!searchTerm.trim()) return text;

      const escapedSearch = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escapa caracteres especiais

      const regex = new RegExp(`(${escapedSearch})`, 'gi'); // Não precisa remover os espaço

      const finalText = text.split(regex).map((part, index) =>
        part.toLowerCase() === searchTerm.toLowerCase() ? (
          <span key={index} className="highlighted-text">
            {part}
          </span>
        ) : (
          part
        ),
      );

      return (
        <span>
          {finalText.map((item, index) =>
            typeof item === 'string' ? (
              item
            ) : (
              <React.Fragment key={index}>{item}</React.Fragment>
            ),
          )}
        </span>
      );
    };

    const OptionListcollectionHistoryOptions = ({
      options,
      selectedIndex,
      setQuery,
      query,
    }) => {
      return (
        <>
          {options.map((option, index) => {
            const Icon =
              option._source.kind === 'collection_history'
                ? Clock
                : SearchNormal1;

            const isSelected = index === selectedIndex;

            return (
              <div
                key={option._id || index}
                style={{
                  padding: '8px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
                className={`option-item ${option._source.kind} ${isSelected ? 'selected' : ''}`}
                onClick={() => setQuery(option.text)}
              >
                <Icon size="18" />
                {highlightText(option.text, query)}
              </div>
            );
          })}
        </>
      );
    };

    const OptionListotherOptions = ({
      options,
      selectedIndex,
      setQuery,
      historyLength,
      query,
    }) => {
      return (
        <>
          {options.map((option, index) => {
            const Icon =
              option._source.kind === 'collection_history'
                ? Clock
                : SearchNormal1;

            const isSelected = index + historyLength === selectedIndex;

            return (
              <div
                key={option._id || index}
                style={{
                  padding: '8px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
                className={`option-item ${option._source.kind} ${isSelected ? 'selected' : ''}`}
                onClick={() => setQuery(option.text)}
              >
                <Icon size="18" />
                {highlightText(option.text, query)}
              </div>
            );
          })}
        </>
      );
    };

    const collectionHistoryOptions = options?.filter(
      (option) => option._source.kind === 'collection_history',
    );
    const otherOptions = options?.filter(
      (option) => option._source.kind === 'ementa',
    );

    const hasSearchBarResults = options.length > 0;

    const stylesValues = {
      backgroundColor: '#ffffff',
      width: '80%',
      zIndex: salueStyles ? 0 : 100,
      height: '80px',
      paddingTop: '18px',
      paddingLeft: '30px',
    };

    return (
      <div style={stylesValues}>
        <div id="search-bar-container">
          <div
            className="input-field "
            style={{ display: 'flex', ...style, flexDirection: 'column' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div className="search-bar-result">
                <InputGroup inside size="lg" ref={this.input}>
                  <Whisper
                    placement="bottom"
                    trigger="hover"
                    speaker={<Tooltip>Mudar o tipo de busca</Tooltip>}
                  >
                    <Button
                      ripple={false}
                      id="btn-search"
                      onClick={this.toggleopenSearch}
                      className="menu-button"
                      style={{ minWidth: '90px' }}
                    >
                      <span className="buttons-result-searchbarresult">
                        {buttonTitle}
                      </span>
                    </Button>
                  </Whisper>
                  <>
                    <Whisper
                      placement="bottom"
                      trigger="hover"
                      speaker={<Tooltip>Mudar o tipo de busca</Tooltip>}
                    >
                      <button
                        style={{ marginLeft: '8px' }}
                        onClick={this.toggleopenSearch}
                      >
                        {openSearch ? (
                          <ArrowUp2 color=" #053AC8" size={14} />
                        ) : (
                          <ArrowDown2 color=" #053AC8" size={14} />
                        )}
                      </button>
                    </Whisper>
                    {openSearch && (
                      <div
                        className="switch-option-searchmode"
                        style={{ zIndex: '100 !important' }}
                        ref={this.searchTypeContainerRef}
                        tabIndex="0"
                      >
                        <div
                          className={
                            !isSearchByNumber
                              ? 'buttons-result-searchbarresult selected'
                              : 'buttons-result-searchbarresult notselected'
                          }
                          onClick={() =>
                            this.handlePopoverSelect(SEARCH_MODE.NORMAL)
                          }
                        >
                          <span style={{ display: 'flex', gap: '6px' }}>
                            Busca{' '}
                            <p
                              style={{
                                color: 'var(--Gray-Gray-400, #98A2B3)',
                                fontFamily: 'Poppins',
                                fontSize: '14px',
                                fontStyle: 'italic',
                                fontWeight: 500,
                              }}
                            >
                              (padrão)
                            </p>
                          </span>

                          {!isSearchByNumber && <TickCircle size="16" />}
                        </div>
                        <div
                          className={
                            isSearchByNumber
                              ? 'buttons-result-searchbarresult selected'
                              : 'buttons-result-searchbarresult notselected'
                          }
                          onClick={() =>
                            this.handlePopoverSelect(SEARCH_MODE.PROCESS_NUMBER)
                          }
                        >
                          Buscar por n° de processo
                          {isSearchByNumber && <TickCircle size="16" />}
                        </div>
                      </div>
                    )}
                  </>
                  <div className="separator" />
                  <div
                    style={{
                      position: 'relative',
                      width: '100%',
                    }}
                  >
                    <Whisper
                      ref={(ref) => (this.searchHistoryRef = ref)}
                      placement="bottomStart"
                      trigger="active"
                      container={this.input.current}
                      speaker={
                        // EXPLANATION: Esse popover aqui mostra o histórico e os controler
                        <Popover
                          id={'search-history-popover'}
                          className="popover-auto-complete"
                          style={{
                            width: '100%',
                            boxShadow:
                              'box-shadow: 0px 4px 25px 0px rgba(209, 233, 255, 0.30)',

                            border: '1px solid #E4E7EC',
                            borderBottomLeftRadius: '8px',
                            borderBottomRightRadius: '8px',
                            zIndex: -1, // fazer o popover aparecer abaixo e atrás da barra de busca
                            borderTop: '0px',
                            transform: 'translateY(-1.5px) ', // Move o Popover 3px para dentro no topo
                          }}
                        >
                          <div className="search-result">
                            <OptionListcollectionHistoryOptions
                              options={collectionHistoryOptions}
                              selectedIndex={this.state.selectedIndex}
                              setQuery={(text) =>
                                this.setState({ query: text })
                              }
                              query={this.state.query}
                            />
                          </div>

                          {collectionHistoryOptions.length > 0 &&
                            otherOptions.length > 0 && (
                              <div
                                style={{
                                  padding: '4px',
                                  color: '#98A2B3',
                                  marginLeft: '4px',
                                  fontSize: '10px',
                                }}
                              >
                                As pessoas também buscam por
                              </div>
                            )}

                          <div
                            className="search-result"
                            style={{ color: '#0D91FE' }}
                          >
                            <OptionListotherOptions
                              options={otherOptions}
                              selectedIndex={this.state.selectedIndex}
                              setQuery={(text) =>
                                this.setState({ query: text })
                              }
                              historyLength={collectionHistoryOptions.length}
                              query={this.state.query}
                            />
                          </div>
                          <div
                            style={{ marginTop: '23px', marginBottom: '23px' }}
                          >
                            <hr />
                          </div>

                          <OperatorsHelperOpen
                            addOperator={this.addOperator}
                            top={true}
                            hasSearchBarResults={hasSearchBarResults}
                            currentPage={'resultpage'}
                          />
                        </Popover>
                      }
                    >
                      <div
                        className="search-bar-input-result"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          marginRight: '5px',
                        }}
                      >
                        <SearchNormal1
                          size="18"
                          color="#D0D5DD"
                          variant="Outline"
                          style={{ marginRight: 4, marginLeft: 8 }}
                        />
                        <Input
                          ref={this.searchInputRef}
                          className={`input-result ${isSearchByNumber ? 'no-spinner' : ''}`}
                          type={isSearchByNumber ? 'text' : 'text'}
                          value={this.state.query}
                          placeholder={
                            isSearchByNumber
                              ? 'Digite o número do processo ou do acórdão'
                              : 'Quais termos deseja buscar?'
                          }
                          onChange={(value) => {
                            this.handleChangeQuery(value);
                          }}
                          onKeyDown={this.handleKeyDown}
                          style={{ flex: 1 }}
                        />
                        {query && (
                          <div
                            className="button-close"
                            style={{
                              fontWeight: 'bold',
                              color: '#D0D5DD',
                              fontSize: '12px',
                              padding: 0,
                              marginRight: 21,
                              cursor: 'pointer',
                            }}
                            onClick={this.handleClearQuery}
                          >
                            <CloseRoundedIcon style={{ fontSize: 16 }} />
                          </div>
                        )}
                        <InputGroup.Button
                          id="btn-search-result"
                          onClick={(e) => {
                            const searchTerm =
                              this.props.filtersProps.query.trim();
                            if (searchTerm.length >= 3) {
                              this.doSearch(e);
                              PostHogSendSearch('search', 'resultpage'); // POSTHOG_NEW
                            } else {
                              toaster.push(
                                <Message
                                  duration={4000}
                                  type="warning"
                                  showIcon
                                  closable
                                >
                                  Sua busca deve ter pelo menos três caracteres
                                </Message>,
                                { placement: 'topEnd' },
                              );
                            }
                          }}
                        >
                          Buscar
                        </InputGroup.Button>
                      </div>
                    </Whisper>
                  </div>
                </InputGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(SearchBar));
