import { Radio, RadioGroup } from 'rsuite';
import { posthog } from '../../../../../requesters';
import { postHubSpotEvent } from '../../../../../services/hubspot';
import './SynonymsFilter.css';

const SynonymsFilter = (props) => {
  const handleChange = (newOption) => {
    const { context, setSynonymsFilter } = props;

    if (context) {
      posthog.capture(context, {
        action: 'change_parameter',
        id: 'synonyms',
      });
      postHubSpotEvent('app_changeparameter', { app_changeparameter: context });
    }

    setSynonymsFilter(newOption);
  };

  return (
    <div className="synonyms-filter">
      <span className="title-option">Incluir Termos Semelhantes</span>

      <div className="synonyms-button-group">
        <button
          type="button"
          className={`synonyms-button ${
            props.synonymsFilter === true ? 'active' : ''
          }`}
          onClick={() => handleChange(true)}
        >
          Sim
        </button>
        <button
          type="button"
          className={`synonyms-button ${
            props.synonymsFilter === false ? 'active' : ''
          }`}
          onClick={() => handleChange(false)}
        >
          Não
        </button>
      </div>
    </div>
  );
};

export default SynonymsFilter;
