import React from 'react';
import './CustomSteps.css';

export default function CustomSteps({ currentStep = 0, steps }) {
  return (
    <div className="custom-steps-container">
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <div className="custom-steps-item-wrapper">
            <div
              className={`
                custom-steps-circle
                ${index < currentStep && 'custom-steps-circle-completed'}
                ${index === currentStep && 'custom-steps-circle-active'}
                ${index > currentStep && 'custom-steps-circle-inactive'}
              `}
            >
              {index < currentStep ? '✔' : index + 1}
            </div>
            <span
              className={`custom-steps-label ${
                index === currentStep
                  ? 'custom-steps-label-active'
                  : 'custom-steps-label-inactive'
              }`}
            >
              {step.title}
            </span>
          </div>

          {index < steps.length - 1 && (
            <div
              className={`custom-steps-separator ${
                index < currentStep
                  ? 'custom-steps-separator-active'
                  : 'custom-steps-separator-inactive'
              }`}
            ></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}
