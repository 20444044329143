import { Input, InputGroup } from 'rsuite';
import './style.scss';
import { useEffect, useState } from 'react';
import { AddCircle, TickCircle } from 'iconsax-react';
import { toast } from 'react-toastify';
import { requester } from '../../../../../requesters';
import { Button, SpinningLoader } from '../../../../../ds-components';
import './style.scss';
import { useCollectionTags } from '../../../../../store/ducks/collectionTags';

import { PostHogSendCollection } from '../../../../../utils/PostHogSendEvents';

const CreateTagButton = ({
  cards,
  collectionId,
  collectionTags,
  onCreateTag,
}) => {
  const profile = JSON.parse(localStorage.getItem('profile'));
  const cardIds = cards.map((card) => card.id);
  const [existTagWithCardInCards, setExistTagWithCardInCards] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editTag, setEditTag] = useState('');
  const { fetch: fetchCollectionTags } = useCollectionTags();

  useEffect(() => {
    getExistTagWithCardInCards();
  }, [collectionTags]);

  function getExistTagWithCardInCards() {
    if (collectionTags == null) {
      setExistTagWithCardInCards(false);
      return;
    }

    let exist = false;
    Object.keys(collectionTags).map((tagIdx) => {
      if (
        cardIds.some((cardId) => collectionTags[tagIdx].cards.includes(cardId))
      ) {
        setExistTagWithCardInCards(true);
        exist = true;
      }
    });
    if (!exist) setExistTagWithCardInCards(false);
  }

  function createTagHandler() {
    setLoading(true);
    const cardIds = cards.map((card) => card.id);

    requester.collections
      .createTag(editTag, collectionId, profile.id)
      .then((response) => {
        PostHogSendCollection('create_maker_collection'); // POSTHOG_NEW
        setEditTag('');
        toast.success(
          <span className="text-base leading-7 text-[#475467]">
            Marcador criado
          </span>,
          {
            icon: <TickCircle size="sm" color="#12B76A" />,
            className: 'w-[312px] right-[26px] cursor-pointer bottom-[50px]',
            progressStyle: { background: '#12B76A' },
          },
        );
        if (!existTagWithCardInCards) {
          addTagHandler(response.id, cardIds);
        } else {
          fetchCollectionTags(collectionId);
        }
        onCreateTag();
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }

  function addTagHandler(tagId, cardIds) {
    requester.collections
      .addCardsToTag(tagId, cardIds)
      .then(() => {
        PostHogSendCollection('add_remove_maker_collection', null, 'add'); // POSTHOG_NEW
        fetchCollectionTags(collectionId);
        toast.success(
          <span className="text-base leading-7 text-[#475467]">
            {cards.length === 1
              ? 'Marcador adicionado'
              : 'Marcadores adicionados'}
          </span>,
          {
            icon: <TickCircle size="sm" color="#12B76A" />,
            className: 'w-[312px] right-[26px] cursor-pointer bottom-[50px]',
            progressStyle: { background: '#12B76A' },
          },
        );
      })
      .catch((err) => console.log(err));
  }

  function keyUpHandler(e) {
    if (e.key === 'Enter') {
      createTagHandler();
    }
  }

  return (
    <div className="px-2 flex flex-row gap-4">
      <InputGroup inside>
        <InputGroup.Addon>
          <AddCircle size="20" color="#98A2B3" />
        </InputGroup.Addon>
        <Input
          placeholder="Insira aqui o nome do novo marcador"
          className="search-tag-container_search-input"
          onKeyUp={(e) => keyUpHandler(e)}
          value={editTag}
          onChange={setEditTag}
          disabled={loading}
        />
      </InputGroup>

      <Button
        className="w-[234px]"
        appearance="blue900"
        size="sm"
        onClick={createTagHandler}
        icon={
          loading ? (
            <SpinningLoader color="white" size="sm" />
          ) : (
            <AddCircle size={16} color="#FFFFFF" />
          )
        }
      >
        Criar marcador
      </Button>
    </div>
  );
};

export default CreateTagButton;
