import { Judge, Send2, Timer } from 'iconsax-react';
import React from 'react';

export type FavorabilityButtonProps = {
  type:
    | 'Favorável ao contribuinte'
    | 'Contrário ao contribuinte'
    | 'Parcialmente favorável ao contribuinte'
    | 'Outros/Indeterminado'
    | 'Sugerir classificação';
  onClick: () => void;
  className?: string;
};

export const FavorabilityButton: React.FC<FavorabilityButtonProps> = ({
  type,
  onClick,
  className,
}) => {
  const renderFavorabilityTag = (typeText: string) => {
    switch (typeText) {
      case 'Favorável ao contribuinte':
        return (
          <button
            className={`tv-btn_favorability-favorable ${
              className ? `${className}` : ''
            }`}
            onClick={onClick}
          >
            <Judge
              size={12}
              color="#12B76A"
              className="tv-btn_favorability-favorable_icon"
            />
            <span className="font-medium">{typeText}</span>
          </button>
        );
      case 'Contrário ao contribuinte':
        return (
          <button
            className={`tv-btn_favorability-unfavorable ${
              className ? `${className}` : ''
            }`}
            onClick={onClick}
          >
            <Judge
              size={12}
              color="#F79009"
              className="tv-btn_favorability-unfavorable_icon"
            />
            <span className="font-medium">{typeText}</span>
          </button>
        );

      case 'Parcialmente favorável ao contribuinte':
        return (
          <button
            className={`tv-btn_favorability-partially ${
              className ? `${className}` : ''
            }`}
            onClick={onClick}
          >
            <Judge
              size={12}
              color="#0086C9"
              className="tv-btn_favorability-partially_icon"
            />
            <span className="font-medium">{typeText}</span>
          </button>
        );
      case 'Outros/Indeterminado':
        return (
          <button
            className={`tv-btn_favorability-other ${
              className ? `${className}` : ''
            }`}
            onClick={onClick}
          >
            <Timer
              size={12}
              color="#667085"
              className="tv-btn_favorability-other_icon"
            />
            <span className="font-medium">{typeText}</span>
          </button>
        );
      case 'Sugerir classificação':
        return (
          <button
            className={`tv-btn_favorability-suggest ${
              className ? `${className}` : ''
            }`}
            onClick={onClick}
          >
            <Send2
              size={12}
              color="#667085"
              className="tv-btn_favorability-suggest_icon"
            />
            <span className="font-medium">{typeText}</span>
          </button>
        );
      default:
        break;
    }
  };
  return <>{renderFavorabilityTag(type)}</>;
};

export default FavorabilityButton;
