import { Tooltip, Whisper } from 'rsuite';
import { useEffect, useState, useRef } from 'react';
import { Button, Progress } from 'rsuite';
import {
  Volume2,
  VolumeX,
  PauseCircle,
  PlayCircle,
  SkipForward,
} from 'lucide-react';
import './Text2SpeechPlayer.css';

export default function TextToSpeechPlayer({ text, tooltip }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [progress, setProgress] = useState(0);
  const [phrases, setPhrases] = useState([]);
  const synth = window.speechSynthesis;
  const utteranceRef = useRef(null);

  useEffect(() => {
    if (text) {
      setPhrases(text.match(/[^.]+\. /g) || [text]);
      // Divide o texto em frases
    }

    return () => {
      synth.cancel();
      setIsPlaying(false);
      setIsPaused(false);
      setCurrentIndex(0);
    };
  }, [text]);

  const speakPhrase = (index) => {
    if (!phrases[index]) {
      setIsPlaying(false);
      return;
    }

    const utterance = new SpeechSynthesisUtterance(phrases[index]);
    utterance.lang = 'pt-BR';
    utterance.onend = () => {
      const nextIndex = index + 1;
      setCurrentIndex(nextIndex);
      setProgress((nextIndex / phrases.length) * 100);

      if (nextIndex < phrases.length) {
        speakPhrase(nextIndex);
      } else {
        setIsPlaying(false);
        setProgress(100);
      }
    };

    utteranceRef.current = utterance;
    synth.speak(utterance);
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      synth.cancel();
      setIsPlaying(false);
      setIsPaused(false);
      setCurrentIndex(0);
      setProgress(0);
    } else {
      speakPhrase(0);
      setIsPlaying(true);
      setIsPaused(false);
      setProgress(0);
    }
  };

  const handlePause = () => {
    if (isPaused) {
      synth.resume();
      setIsPaused(false);
    } else {
      synth.pause();
      setIsPaused(true);
    }
  };

  const handleSkip = () => {
    if (currentIndex < phrases.length - 1) {
      synth.cancel();
      setCurrentIndex((prev) => prev + 1);
      speakPhrase(currentIndex + 1);
    } else {
      setIsPlaying(false);
      setProgress(100);
    }
  };

  return (
    <div>
      {!isPlaying ? (
        <Whisper
          trigger="hover"
          placement="left"
          speaker={
            <Tooltip
              style={{
                gap: '8px',
                display: 'inline-flex',
                alignItems: 'center',
                height: '32px',
                width: '163px',
              }}
            >
              <Volume2 size="12" color="#ffffff" />
              {tooltip}
            </Tooltip>
          }
        >
          <Button
            onClick={handlePlayPause}
            variant="outline"
            style={{
              padding: '5px',
              border: '1px solid #e4e7ec',
              backgroundColor: 'white',
            }}
            className="ml-2"
          >
            {isPlaying ? <VolumeX size={16} /> : <Volume2 size={16} />}
          </Button>
        </Whisper>
      ) : (
        <div className="audio-player">
          {/* <Progress.Line percent={progress} strokeColor="#007bff" /> */}

          <div className="controls">
            <Button
              style={{
                padding: '5px',
                marginLeft: '2px',
                border: '1px solid #e4e7ec',
                backgroundColor: 'white',
              }}
              onClick={handlePlayPause}
            >
              <VolumeX size={16} />
            </Button>
            <Button
              style={{
                padding: '5px',
                marginLeft: '2px',
                border: '1px solid #e4e7ec',
                backgroundColor: 'white',
              }}
              onClick={handlePause}
            >
              {isPaused ? <PlayCircle size={16} /> : <PauseCircle size={16} />}
            </Button>
            <Button
              style={{
                marginLeft: '2px',
                padding: '5px',
                border: '1px solid #e4e7ec',
                backgroundColor: 'white',
              }}
              onClick={handleSkip}
              disabled={currentIndex >= phrases.length - 1}
            >
              <SkipForward size={16} />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
