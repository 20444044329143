import closeCircleIcon from '../../../assets/svgs/close-circle.svg';
import { Tooltip, Whisper } from 'rsuite';
import { ArrowLeft } from 'iconsax-react';
import { DialogClose } from '../../../components/ui/dialog';
import { ResumeIABulkActionContext } from '../ResumeIABulkActionContext/ResumeIABulkActionContext';

import './ResumeIABulkActionContainer.css';
import { useEffect } from 'react';

export const ResumeIABulkActionContainer = ({
  setOpen,
  open,
  collection,
  currentPage,
  contextPage,
  countResults,
  firstCards,
  pageView = '',
  isItInACollection,
}) => {
  const stopPropagationOnClick = (e) => {
    e?.stopPropagation();
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setOpen(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setOpen]);

  return (
    <div
      className="resume-ia-bulkaction-container"
      onClick={stopPropagationOnClick}
    >
      <button
        className="close-button-resume-in-bulkaction"
        onClick={() => setOpen(false)}
      >
        <ArrowLeft size="12" color="#667085" />
        Voltar
      </button>
      <Whisper
        trigger={'hover'}
        placement="left"
        speaker={<Tooltip>Fechar</Tooltip>}
      >
        <DialogClose
          className="close-circle-button-resume-in-bulkaction hover:opacity-100 focus:outline-none focus:ring-0 focus:ring-ring focus:ring-offset-0 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
          onClick={() => setOpen(false)}
        >
          <img src={closeCircleIcon} />
          <span className="sr-only">Close</span>
        </DialogClose>
      </Whisper>
      <ResumeIABulkActionContext
        collection={collection}
        currentPage={currentPage}
        contextPage={contextPage}
        countResults={countResults}
        open={open}
        firstCards={firstCards}
        pageView={pageView}
        isItInACollection={isItInACollection}
      />
    </div>
  );
};
