import { ArrowDown2, ArrowUp2 } from 'iconsax-react';
import { posthog } from '../../../../../requesters';
import { Button } from '../../../../../ds-components';
import { postHubSpotEvent } from '../../../../../services/hubspot';

import { PostHogSendResultPage } from '../../../../../utils/PostHogSendEvents';

const ReadMoreOrLessButton = ({ showAllText, setShowAllText, cardId }) => {
  return (
    <Button
      appearance="secondary"
      size="sm"
      icon={
        showAllText ? (
          <ArrowUp2 size={16} color="#010c28" />
        ) : (
          <ArrowDown2 size={16} color="#010c28" />
        )
      }
      iconPosition="right"
      onClick={(e) => {
        // Impede o evento de chegar na `div`
        // É importante pq senão quandi clicamos em ler mais ele vai ficar adicionando a decisão no bulkaction
        e.stopPropagation();

        PostHogSendResultPage(
          showAllText ? 'read_less_resultpage' : 'read_more_resultpage',
        ); // POSTHOG_NEW

        setShowAllText(!showAllText);
        posthog.capture('open_details', {
          action: 'open_details',
          id: cardId,
        });
        postHubSpotEvent('app_opendetails', { app_opendetails: cardId });
      }}
    >
      {showAllText ? 'Reduzir ementa' : 'Expandir ementa'}
    </Button>
  );
};

export default ReadMoreOrLessButton;
