import api from './api';
import { useMutation } from '@tanstack/react-query';
import { ResumeIARequest, ResumeIAResponse } from './types';

const endpoints = {
  fetchResumeIA: () => `batch_summary/`,
};

const ResumeIAApi = {
  UsefetchResumeIA: () =>
    useMutation({
      mutationFn: async ({ user_id, card_ids }: ResumeIARequest) => {
        const response = await api.post<ResumeIARequest, ResumeIAResponse>(
          endpoints.fetchResumeIA(),
          {
            user_id,
            card_ids,
          },
        );
        return response;
      },
    }),
};

export const { UsefetchResumeIA } = ResumeIAApi;
